import React from 'react';
import { DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers/DateTimePicker';

interface DatePickerMuiProps extends Omit<DateTimePickerProps<Date, false>, 'value'> {
    label: string;
    value: Date | null;
    onChange: (date: any) => void;
    disableDateBefore?: Date;
    disableDateAfter?: Date;
}

const DatePickerMui: React.FC<DatePickerMuiProps> = (props) => {
    const { value, onChange, disableDateBefore, disableDateAfter, ...rest } = props;

    const disableDate = (date: Date) => {
        if (disableDateBefore && date < disableDateBefore) {
            return true;
        }
        if (disableDateAfter && date > disableDateAfter) {
            return true;
        }
        if (disableDateBefore && disableDateAfter) {
            // Interdire les dates et heures qui sont égales
            if (date.getTime() === disableDateBefore.getTime() || date.getTime() === disableDateAfter.getTime()) {
                return true;
            }
            // Contrôler les heures pour les dates égales à disableDateBefore et disableDateAfter
            if (date.getTime() === disableDateBefore.getTime()) {
                // Comparer les heures
                return date.getHours() < disableDateBefore.getHours();
            }
            if (date.getTime() === disableDateAfter.getTime()) {
                // Comparer les heures
                return date.getHours() > disableDateAfter.getHours();
            }
        } else if (disableDateBefore && date.getTime() === disableDateBefore.getTime()) {
            // Comparer les heures pour disableDateBefore
            return date.getHours() < disableDateBefore.getHours();
        } else if (disableDateAfter && date.getTime() === disableDateAfter.getTime()) {
            // Comparer les heures pour disableDateAfter
            return date.getHours() > disableDateAfter.getHours();
        }
        return false;
    };
    

    return (
        <DateTimePicker
            {...rest}
            value={value}
            onChange={onChange}
            shouldDisableDate={disableDate}
            ampm={false}
            sx={{ 
                '.MuiInputBase-root' : {
                    height: 40, 
                }, 
                '.MuiInputBase-input': { 
                height: 50, 
                padding: '5px 14px', 
                fontSize: '0.875rem' // Taille de la police ajustée
                }, 
                '.MuiInputLabel-root': { 
                    height: 50, 
                lineHeight: '1rem', // Ajustez selon vos besoins
                top: '-5px', // Ajustez la position de l'étiquette si nécessaire
                color: '#FFFFFF', // Couleur du label en focus
                display : "flex"
    
                }, 
                '.MuiInputLabel-shrink': { 
                    color: 'white', // Couleur du label en focus
                    top: '5px!important', // Ajustez la position de l'étiquette si nécessaire
                },
    
                '.MuiInputLabel-root.Mui-focused': { 
                    color: '#6edf90', // Couleur du label en focus
                    top: '5px!important', // Ajustez la position de l'étiquette si nécessaire
                },
                '& .MuiOutlinedInput-root': {
                    // Bordure en non focus
                    '& fieldset': {
                    borderColor: 'white',
                    },
                    // Bordure en hover (non focus)
                    '&:hover fieldset': {
                    borderColor: '#6edf90',
                    },
                    // Bordure en focus
                    '&.Mui-focused fieldset': {
                    borderColor: '#6edf90',
                    },
                },
    
            }} 
        />
    );
};

export default DatePickerMui;
