import { useEffect, useState } from "react";
import { ServerProps } from "../../../config/server.config";
import BasePopup from "../../../tools/components/general/BasePopup";
import { ServiceModel } from "../../../database/services-model.db";
import SearchSelect from "../../../tools/components/general/SearchSelect";
import MainButton from "../../../tools/components/general/MainButton";
import { useDispatch, useSelector } from "react-redux";
import { addRequestStatus, setLoaderStatus } from "../../../store/global/actions.global";
import { account_user } from "../../../store/account/selector.account";
import ContainerLoad from "../../../tools/components/general/ContainerLoad";




export interface ServiceManProps extends ServerProps {
    handleClose: () => void;
    setData : React.Dispatch<React.SetStateAction<any>>;
    update? : { 
        status : boolean, 
        content : ServiceModel
    }, 
}


interface ServiceManManager {
    load : boolean
}

export default function ServiceMan(props:ServiceManProps) {
    // * GENERAL VARIABLES 
    const dispatch = useDispatch()
    const user_account = useSelector(account_user)
    
    // * STATES
    const [manager, setManager] = useState<ServiceManManager>({
        load : false
    })
    const [form, setForm] = useState<ServiceModel>({
        code : "", 
        label : "", 
        unit_price : "", 
        family : {
            _id: null, 
        },
        subfamily : {
            _id: null, 
        }, 
        deleted : false, 
        date_insert : new Date()
    })
    

    // * FUNCTIONS
    const handleAddFamily = (value:string) => {
        props.server.post('configuration/service_families/add-family', {new_family_name : value, date : new Date(), user : user_account.infos._id})
        .then(res => {
            dispatch(addRequestStatus({
                status : true, 
                message : res.data.message
            }))
            //setSearchManager((state:any) => ({...state, open : false, status : "pending", input : value}))
            dispatch(setLoaderStatus({status : false}))
        })
        .catch(err => {
            dispatch(addRequestStatus({
                status : err.response.data.status, 
                message : err.response.data.message
            }))
            //setSearchManager((state:any) => ({...state, open : false, status : "resolve", input : ""}))
            dispatch(setLoaderStatus({status : false}))
        })
    }

    const handleAddSubFamily = (value:string,) => {
        props.server.post('configuration/service_families/add-subfamily', {family_id : form.family._id ,new_subfamily_name : value, date : new Date(), user : user_account.infos._id})
        .then(res => {
            dispatch(addRequestStatus({
                status : true, 
                message : res.data.message
            }))
            setForm((state:any) => ({...state, subfamily : {_id : res.data.data._id}}))
            //setSearchManager((state:any) => ({...state, open : false, status : "pending", input : value}))
            dispatch(setLoaderStatus({status : false}))
        })
        .catch(err => {
            dispatch(addRequestStatus({
                status : err.response.data.status, 
                message : err.response.data.message
            }))
            //setSearchManager((state:any) => ({...state, open : false, status : "resolve", input : ""}))
            dispatch(setLoaderStatus({status : false}))
        })
    }

    const handleAddService = () => {
        if([`${form.code}`.trim(), `${form.label}`.trim(), !`${form.unit_price}`].includes("")) {
            dispatch(addRequestStatus({
                status : false, 
                message : "Veuillez remplir tous les champs"
            }))
            return
        }

        dispatch(setLoaderStatus({status : true}))

        const formContainer = {
            ...form, 
            user : user_account.infos._id,
            family : form.family._id,
            subfamily : form.subfamily._id, 
            date_insert : new Date()
        }
        

        props.server.post('general/services/add', formContainer)
        .then(res => {
            dispatch(addRequestStatus({
                status : true, 
                message : res.data.message
            }))
            props.handleClose()
            props.setData((state:any) => {
                return [...state, res.data.data]
            })
            dispatch(setLoaderStatus({status : false}))
        })
        .catch(err => {
            dispatch(addRequestStatus({
                status : err.response.data.status, 
                message : err.response.data.message
            }))
            dispatch(setLoaderStatus({status : false}))
        })
    }

    const handleChangeInput = (e:React.ChangeEvent<HTMLInputElement>, reg:RegExp|string = '') => {
        var value = e.target.value
        
        if (reg instanceof RegExp && !reg.test(value)) {
            // La saisie de l'utilisateur ne correspond pas à l'expression régulière,
            // vous pouvez gérer cela ici (par exemple, afficher un message d'erreur).
            return;
        }

        if(['total_tva', 'total_ttc'].includes(e.target.name)) {
            value = value.replace(',', '.')
        }

        setForm((state:any) => ({...state, [e.target.name] : value}))
        //props.setData(state => {return state.map(st => st.url === props.selected_data ? )})
    }
    
    const handleUpdate = () => {
        if([`${form.code}`.trim(), `${form.label}`.trim(), !`${form.unit_price}`].includes("")) {
            dispatch(addRequestStatus({
                status : false, 
                message : "Veuillez remplir tous les champs"
            }))
            return
        }

        dispatch(setLoaderStatus({status : true}))

        const formContainer = {
            ...form, 
            user : user_account.infos._id,
            family : form.family._id,
            subfamily : form.subfamily._id, 
            date_insert : new Date()
        }
        

        props.server.post('general/services/update', formContainer)
        .then(res => {
            dispatch(addRequestStatus({
                status : true, 
                message : res.data.message
            }))
            props.handleClose()
            props.setData((state:any) => {
                return state.map((el:any) => {
                    if(el._id === form._id) {
                        return res.data.data
                    } else {
                        return el
                    }
                })
            })
            dispatch(setLoaderStatus({status : false}))
        })
        .catch(err => {
            dispatch(addRequestStatus({
                status : err.response.data.status, 
                message : err.response.data.message
            }))
            dispatch(setLoaderStatus({status : false}))
        })
    }
    
    useEffect(() => {
        if(props.update?.status) {
            const data = {
                ...props.update.content, 
                family : props.update.content.family === null ? {_id : null} : props.update.content.family,
                subfamily : props.update.content.subfamily === null ? {_id : null} : props.update.content.subfamily
            }
            setForm(data)
        }
    }, [])

    return (
        <BasePopup nameClass="services-man" handleClose={props.handleClose}>
            <ContainerLoad load={manager.load}>
            <div className="code">
                <label htmlFor="code">Code</label>
                <input 
                    type="text" 
                    placeholder="Code du service"
                    name="code"
                    value={form.code}
                    onChange={(e) => e.target.value.length < 9 ? handleChangeInput(e, /^[a-zA-Z0-9]*/g) : null}
                />
            </div>
            <div className="name">
                <label htmlFor="name">Nom</label>
                <input 
                    type="text" 
                    placeholder="Nom du service" 
                    name="label"
                    value={form.label}
                    onChange={(e) => handleChangeInput(e)}
                />
            </div>
            <div className="unit-price">
                <label htmlFor="unit_price">Prix unitaire</label>
                <input 
                    type="text" 
                    placeholder="Prix unitaire"
                    name="unit_price"
                    value={form.unit_price}
                    onChange={(e) => setForm((state:any) => ({...state, unit_price : e.target.value.replace(/[^-0-9,.]/g, '').replace(/,/g, '.')}))}
                />
            </div>
            <div className="family">
                <label htmlFor="family">Famille</label>
                <SearchSelect
                {...{
                    adding : true, 
                    handleAdd : (value:string) => handleAddFamily(value), 
                    text_no_data : "Aucun famille trouvé", 
                    handleClick : (data) => {
                        setManager((state:any) => ({...state, load : true}))
                        setForm((state:any) => {return {...state, family : {_id : data.id}, subfamily : {_id : null}}}) ; 

                        setTimeout(() => {
                            setManager((state:any) => ({...state, load : false}))
                        }, 800);
                    },
                    handleDelete : () => setForm((state:any) => {return {...state, family : {_id : null}}}),
                    no_data_text : "Aucune catégorie trouvé", 
                    server : {
                        axios : props.server,
                        route : "/configuration/service_families/search-families",
                    },
                    value : form.family._id
                }}
                />
            </div>

            {
                form.family._id !== null ? 
                    <div className="subfamily">
                        <label htmlFor="subfamily">Sous-famille</label>
                        <SearchSelect
                            {...{
                                adding : true, 
                                handleAdd : (value:string) => handleAddSubFamily(value), 
                                text_no_data : "Aucun sous-famille trouvée", 
                                handleClick : (data) => setForm((state:any) => {return {...state, subfamily : {_id : data.id}}}),
                                handleDelete : () => setForm((state:any) => {return {...state, subfamily : {_id : null}}}),
                                no_data_text : "Aucun sous catégorie trouvée", 
                                server : {
                                    axios : props.server,
                                    route : "/configuration/service_families/search-subfamilies",
                                    body : {
                                        family_id : form.family._id
                                    }
                                },
                                value : form.subfamily._id
                            }}
                        />
                    </div>
                : 
                    null
            }

            {
                props.update?.status ?
                    <MainButton
                        handleClick = {handleUpdate}
                    >
                        {"Modifier"}
                    </MainButton>
                : 
                    <MainButton
                    handleClick = {handleAddService}
                    >
                        {"Créer"}
                    </MainButton>
            }
            </ContainerLoad>

        </BasePopup>
    )
}