
import React from "react"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { fr } from 'date-fns/locale';
import DatePickerMui from "../../base/DatePickerMui";
import { CollectorForm, dateTimeForm, mui_sx, timeForm } from "../horologer.utils";
import { TextField } from "@mui/material";
export interface DateTimeManagerMan {
    mode_time : "date_time" | "time"
}

export interface DateTimeManagerProps {
    manager : DateTimeManagerMan,
    setManager : React.Dispatch<React.SetStateAction<DateTimeManagerMan>>,
    form : CollectorForm,
    setForm : React.Dispatch<React.SetStateAction<CollectorForm>>
}

export default function DateTimeManager(props:DateTimeManagerProps) {
    
    
    return (
        <div className = "date-time-manager">
            <div className="date-time-manager--mode-time">
                <div
                    id = {`${props.manager.mode_time === "date_time" ? "mode-time--active" : {}}`}
                    onClick = {() => props.setManager((state:any) => {return {...state, mode_time : "date_time"}})}
                >
                    Date et heure
                </div>
                <div
                    id = {`${props.manager.mode_time === "time" ? "mode-time--active" : {}}`}
                    onClick = {() => props.setManager((state:any) => {return {...state, mode_time : "time"}})}
                >
                    Heure
                </div>
            </div>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
                    <div className="date-time-manager--choose-date">
                        {
                            props.manager.mode_time === "date_time" ? 
                                    <React.Fragment>
                                        <DatePickerMui
                                            label = "Date de début"
                                            value = {Object.keys(props.form.date_time).length > 0 ? (props.form.date_time as dateTimeForm).start : null}
                                            onChange = {(newValue) => {
                                                props.setForm((state:any) => {return {...state, date_time : {...state.date_time, start : newValue}}})
                                            }}
                                            disableDateAfter={(props.form.date_time as dateTimeForm).end} 
                                        />
                                        {
                                            Object.keys(props.form.date_time).length > 0 ? 
                                                <DatePickerMui
                                                label = "Date de fin"
                                                value = {Object.keys(props.form.date_time).length > 0 ? (props.form.date_time as dateTimeForm).end : null}
                                                onChange = {(newValue) => {
                                                    props.setForm((state:any) => {return {...state, date_time : {...state.date_time,end : newValue}}})
                                                }}
                                                disableDateBefore={(props.form.date_time as dateTimeForm).start} 
                                            /> 
                                            : null
                                        }
                
                                    </React.Fragment>
                                : 
                                    <React.Fragment>
                                            <DatePicker
                                                label = "Date"
                                                value = {Object.keys(props.form.time).length > 0 ? (props.form.time as timeForm).date : null}
                                                onChange = {(newValue) => {
                                                    props.setForm((state:any) => {return {...state, time : {...state.time, date : newValue}}})
                                                }}
                                                sx={mui_sx} 
                                            /> 
                                            <TextField
                                                label = "Total heures"
                                                variant="outlined"
                                                value = {Object.keys(props.form.time).length > 0 ? (props.form.time as timeForm).total : ''}
                                                onChange={(e) => {
                                                    props.setForm((state:any) => {return {...state, time : {...state.time, total : e.target.value}}})
                                                }}
                                                sx={mui_sx} 
                                            />
                                            

                                    </React.Fragment>
                        }
                    </div>
                </LocalizationProvider>
        </div>
    )
}