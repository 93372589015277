import React, {useEffect} from 'react'
import {motion} from 'framer-motion'
import { StatusRequestType } from '../../../../store/types.store'



interface StatusRequestInterface {
    response : StatusRequestType, 
    closeStatusRequest : () => void, 
    duration? : number
}

const StatusRequest:React.FC<StatusRequestInterface> = (props) => {
    const duration = props.duration?? 3000
    useEffect(() => {
        const timer = setTimeout(() => {
            props.closeStatusRequest()
        }, duration);
        return () => clearTimeout(timer);
    },[props.response, props.closeStatusRequest, duration, props.duration, props]);

    return (
        <motion.div 
            key = {JSON.stringify(props.response)}
            exit = {{y : -200, opacity : 0}}
            
            initial = {{y : -200, opacity : 0}}
            animate = {{y : 0, opacity : 1}}
            transition = {{duration : 0.1}}
            //variants={statusRequest__animation}
            onClick={() => props.closeStatusRequest()}
            className={`statusRequest statusRequest__${props.response.status ? "success" : props.response.status === null ?   "info" : "error"}`}
        >
            <motion.div
                exit = {{scale : 0.5}}
                animate = {{scale : 1, transition : {delay : 0.1, duration : 0.1}}}
                initial = {{scale : 0.5}}
            ></motion.div>
            <motion.p
                exit = {{y : -50, opacity : 0}}
                animate = {{y : 0, opacity : 1, transition : {delay : 0.3, duration : 0.1}}}
                initial = {{y : -50, opacity : 0}}        
            >{props.response.message}</motion.p>
        </motion.div>
    )
}

export default StatusRequest ; 