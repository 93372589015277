import React, { useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { icons_config } from '../../../../../config/context.config';
import { TableForProps } from 'tc-minibox/dist/types/table/index.table';
import {Table} from 'tc-minibox'

export interface ActivityProps {

}

ChartJS.register(ArcElement, Tooltip, Legend);


export default function Activity(props:ActivityProps) {
    const [tasks, setTasks] = useState<any[]>([])

    const data = {
        labels: ['Comptabilité', 'Assistance', 'Stratégie'],
        datasets: [
          {
            label: 'Répartition des Services',
            data: [300, 150, 100], // Vous pouvez ajuster ces valeurs en fonction de la réalité de votre application
            backgroundColor: [
              '#6edf90', // couleur pour Comptabilité
              'white', // couleur pour Assistance
              '#89fcac', // couleur pour Stratégie
            ],
            borderColor: [
              '#6edf90', // bordure pour Comptabilité
              'white', // bordure pour Assistance
              '#89fcac', // bordure pour Stratégie
            ],
            borderWidth: 1
          }
        ]
    };

    const tbody = tasks

    const table_config:TableForProps = {
        height : 45, 
        editing : false, 
        icons : icons_config, 
        config : [
            {
                info : {
                    text : "Code", 
                    name : "code", 
                }, 
    
                style : {
                    width : "100px",
                }
            },
            {
                info : {
                    text : "Nom", 
                    name : "name", 
                }, 
    
                style : {
                    width : "calc(100% - 200px - 100px)",
                }
            },
            {
                info : {
                    text : "Choix de paiement", 
                    name : "payment_choice",
                }, 
                style : {
                    width : `200px`, 
                    tbody : {
                        textAlign : "center"
                    }
                }
            },  
        ], 
        data : tbody,
        setData : setTasks,
        header : {
            buttons : { 
                /*
                actions : {
                    add: () => setManager(state => {return {...state, add : true}}), 
                    delete : (e:any) => {
                        if(e.length === 0) {
                            return dispatch(addRequestStatus({
                                status : false, 
                                message : "Veuillez sélectionner au moins un client."
                            }))
                        }

                        return dispatch(set_popup_warning({
                            status : true, 
                            text : "Êtes-vous sûr de vouloir supprimer ce(s) client(s) ?", 
                            handleYes : () => handleDelete(e)
                        }))

                    }, 
                }*/
            }
        }, 
        handleDoubleClick : (tr:any) => console.log(tr)
    }
      
    return (
        <div className='selected-client-activity'>
            <div className='selected-client-activity--header'>
                
            </div>
            <div className='selected-client-activity--container'>
                <div id='task_list'>
                    <Table {...table_config} />
                </div>
                <div id="chart">
                    <Pie 
                        data={data} 
                        options = {{
                            plugins: {
                              legend: {
                                display: false // Désactivation de la légende
                              }
                            }
                        }}
                    />
            </div>
            </div>


        </div>
    )
}