import { Axios } from 'axios';
import { AuthType } from '../../store/types.store';
import Homepage from '../base/Homepage';
import Horologer from '../horologer/Horologer';
import Services from '../services/Services';
import Clients from '../clients/Clients';

export interface NavigationProps {
    server : Axios, 
    lang? : any
}

export interface NavigationParams extends NavigationProps {
    account : AuthType
}

type NavigationItem = {
    id : string,
    id_menu : number,
    content : string,
    svg : string,
    path : string,
    component ?: JSX.Element, 
}

export const navigation = (props:NavigationProps):NavigationItem[] => {

    return [
        {id : 'N-00', id_menu : 0, content : props.lang === "en" ? "Tableau de bord" : "Tableau de bord", svg : "grid", path : '/', component : <Homepage {...props}/>}, 
        {id : 'N-01', id_menu : 1, content : props.lang === "en" ? "Clients" : "Clients", svg : "id-badge", path : '/clients', component : <Clients {...props} />}, 
        {id : 'N-02', id_menu : 2, content : props.lang === "en" ? "Prestations" : "Prestations", svg : "gear", path : '/services', component : <Services {...props} />}, 
        {id : 'N-03', id_menu : 3, content : props.lang === "en" ? "Horologer" : "Horologer", svg : "stopwatch", path : '/horologer', component : <Horologer {...props} />},  
        {id : 'N-04', id_menu : 4, content : props.lang === "en" ? "Tickets" : "Tickets", svg : "ticket", path : '/suppliers', component : <Clients {...props} />},

    ]
}

export const navigation_data = (props:NavigationParams) => {
    /*if(props.account.infos.user_group === "direction") {
        return navigation({server : props.server})
    }*/
    // ! A CONFIGURER
    //const access_id = props.account.infos.access.map((acc:any) => acc.id_menu)
    //return navigation({server : props.server}).filter(nav => [1, ...access_id].includes(nav.id_menu) )

    return navigation({server : props.server, lang : props.lang})
}