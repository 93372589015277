import React, { useEffect, useRef, useState } from 'react'
import { ListingHoroManager } from '../layouts/ListingHoro'
import { ServerProps } from '../../../config/server.config'
import TableTasks from './TableTasks'
import PopupAddTask from './PopupAddTask'
import {motion} from 'framer-motion' ; 
import DateTimeManager, { DateTimeManagerMan } from './DateTimeManager'
import { useDispatch } from 'react-redux'
import { addRequestStatus, set_popup_warning, setLoaderStatus } from '../../../store/global/actions.global'
import { calculateHoursDifference, dateTimeForm, timeForm } from '../horologer.utils'
import { ObjectUtils } from 'tc-minibox'


export interface SelectedTaskProps extends ServerProps {
    setManager : React.Dispatch<React.SetStateAction<ListingHoroManager>>
    manager : ListingHoroManager, 
    setData : React.Dispatch<React.SetStateAction<any>>
}

interface SelectedTaskManager extends DateTimeManagerMan {
    add_task : boolean, 


}

export default function SelectedTask(props:SelectedTaskProps) {
    const dispatch = useDispatch()

    const [form, setForm] = useState<any>(props.manager.selected_task)
    const titleRef = useRef<HTMLInputElement>(null);
    const [isFocused, setIsFocused] = useState<boolean>(false);
    const [manager, setManager] = useState<SelectedTaskManager>({
        add_task : false, 
        mode_time : Object.values(form.time).length > 0 ? "time" : "date_time"
    })

    const name = form.name.trim().length > 0 ? form.name : form.tasks_descriptions.map((task:any) => task.content).join(", ")

    const data_form = {
        ...form, 
        name : name, 
        date_time : Object.values(form.date_time).length > 0 ? {
            start : new Date(form.date_time.start), 
            end : new Date(form.date_time.end)
        } : {}, 
        time : Object.values(form.time).length > 0 ? {
            ...form.time, 
            date : new Date(form.time.date)
        } : {}
    }

    const total_datetime_hours = () => {
        const dateTimeForm = form.date_time as dateTimeForm;
    
        // Vérifier si date_time n'est pas vide
        if (dateTimeForm && typeof dateTimeForm === 'object') {
            // Vérifier si start et end existent
            if (dateTimeForm.start && dateTimeForm.end) {
                // Vérifier si start et end sont des objets Date valides
                if (dateTimeForm.start instanceof Date && dateTimeForm.end instanceof Date) {
                    // Conversion de la différence en heures
                    const hoursDiff = calculateHoursDifference(dateTimeForm.start, dateTimeForm.end);
    
                    return hoursDiff; // Retourner le résultat
                } else {
                    // Les dates de début et de fin ne sont pas des objets Date valides
                    return null;
                }
            } else {
                // Les propriétés start et end ne sont pas définies
                return null;
            }
        } else {
            // date_time est vide ou n'est pas un objet
            return null;
        }
    }
    const total_task_description = form.tasks_descriptions.length > 0 ? form.tasks_descriptions.map((task:any) => `${task.hours}` !== "" ? task.hours : 0).reduce((a:any, b:any) => parseFloat(a as any) + parseFloat(b as any), 0) : null
    const rest_hours = () => {
        
        if(manager.mode_time === "date_time") {
            if(total_datetime_hours() !== null && total_task_description !== null) {
                return total_datetime_hours() as number - parseFloat(`${total_task_description}`)
            }
            if(total_task_description === null) {
                return total_datetime_hours() as number
            }
            return null
        }
        if(!(form.time as timeForm).total || (form.time as timeForm).total as any === "") {

            return null
        }

        if(total_task_description === null) {
            return (form.time as timeForm).total
        }

        
        return total_task_description !== null ? parseFloat(`${total_task_description}`) - (form.time as timeForm).total : null
        
    }

    useEffect(() => {
        const handleFocus = () => {
          setIsFocused(true);
        };
    
        const handleBlur = () => {
          setIsFocused(false);
        };
    
        const inputElement = titleRef.current;
        if (inputElement) {
          inputElement.addEventListener('focus', handleFocus);
          inputElement.addEventListener('blur', handleBlur);
    
          return () => {
            inputElement.removeEventListener('focus', handleFocus);
            inputElement.removeEventListener('blur', handleBlur);
          };
        }
    }, []) ; 

    const handleDelete = (e:any) => {
        dispatch(setLoaderStatus({status : true}))
        const selected_data = e.map((sup:any) => sup._id)
        props.server.post('general/agenda/delete', {ids_to_delete : selected_data})
        .then(res => {
            props.setData((state:any) => state.filter((st:any) => !selected_data.includes(st._id)))
            dispatch(addRequestStatus({
                status : true, 
                message : res.data.message
            }))
            dispatch(setLoaderStatus({status : false}))
        })
        .catch(err => {
                dispatch(addRequestStatus({
            status : err.response.data.status, 
            message : err.response.data.message
        }))
            dispatch(setLoaderStatus({status : false}))
        })
    }

    const handleSubmit = () => {

        if(manager.mode_time === "date_time") {
            if(total_datetime_hours() === null){
                return dispatch(addRequestStatus({
                    status : false, 
                    message : "Veuillez définir une date et une heure valide"
                }))
            } 

            if(total_datetime_hours() === 0) {
                return dispatch(addRequestStatus({
                    status : false, 
                    message : "Le total d'heures entre la date de début et la date de fin ne peut pas être égal à 0"
                }))
            }

            if(total_task_description !== total_datetime_hours()) {
                return dispatch(addRequestStatus({
                    status : false, 
                    message : "Le total d'heures entre les missions et la date de début et de fin ne sont pas égaux"
                }))
            }
        }

        if(manager.mode_time === "time") {
            if((!(form.time as timeForm).date || !(form.time as timeForm).total || [null, 0].includes((form.time as timeForm).total) || (form.time as timeForm).date === null)) {
                return dispatch(addRequestStatus({
                    status : false, 
                    message : "Veuillez définir une date et une temps d'heure valide"
                }))
            }
            if(parseFloat(`${total_task_description}`) !== parseFloat((form.time as timeForm).total as any)) {
                return dispatch(addRequestStatus({
                    status : false, 
                    message : "Le total d'heures entre les missions et le total d'heures ne sont pas égaux"
                }))
            }
        }

        if([null, 0].includes(total_task_description)) {
            return dispatch(addRequestStatus({
                status : false, 
                message : "Veuillez définir des missions"
            }))
        }

        if(form.tasks_descriptions.map((task:any) => task.client).includes(null)) {
            return dispatch(addRequestStatus({
                status : false, 
                message : "Veuillez définir un client pour chaque mission"
            }))
        }

        if(form.tasks_descriptions.map((task:any) => task.service).includes(null)) {
            return dispatch(addRequestStatus({
                status : false, 
                message : "Veuillez définir un service pour chaque mission"
            }))
        } 
 
        if(form.tasks_descriptions.map((el:any) => ['', 0].includes(el.hours) ? 0 : el.hours).includes(0)) {
            return dispatch(addRequestStatus({
                status : false, 
                message : "Veuillez définir des heures pour chaque mission"
            })) 
        }

        var formContainer = form

        if(manager.mode_time === "date_time") {
            formContainer = {
                ...form, 
                time : {}
            }
        } else {
            formContainer = {
                ...form, 
                date_time : {}
            }
        }

        formContainer = {
            ...formContainer, 
            tasks_descriptions : form.tasks_descriptions.map((task:any) => {
                return {
                    ...task, 
                    client : task.client._id, 
                    service : task.service._id
                }
            }), 

        }

        dispatch(setLoaderStatus({status : true}))

        
        props.server.post('general/agenda/update', formContainer)
        .then(res => {
            props.setData((state:any) => state.map((st:any) => {
                if(st._id === form._id) {
                    return {
                        ...formContainer, 
                        _id : form._id
                    }
                }
                return st
            }))
            dispatch(addRequestStatus({
                status : true, 
                message : res.data.message
            }))
            dispatch(setLoaderStatus({status : false}))
        })
        .catch(err => {
                dispatch(addRequestStatus({
            status : err.response.data.status, 
            message : err.response.data.message
        }))
            dispatch(setLoaderStatus({status : false}))
        })




    }

    //console.log(Object.values(form.time))
    return (
        <div 
            className="selected-task"
        >
                { 
                    manager.add_task ? 
                        <PopupAddTask
                            server={props.server}
                            handleClose={() => setManager((state:any) => {return {...state, add_task : false}})}
                            setTbody={setForm}
                        /> 
                    : 
                        null
                }
            <div  
                className="selected-task--background"
                onClick = {() => props.setManager((state:ListingHoroManager) => {
                    return {
                        ...state, 
                        selected_task : null
                    }
                })}
            ></div>
            <motion.div 
                initial = {{right : -600}}
                animate = {{right : 0, transition : {duration : 0.3}}}
                exit = {{right : -600}}
                className="selected-task--container"
            >
                <div 
                    className='selected-task--delete'
                    onClick = {() => {
                        dispatch(set_popup_warning({
                            status : true, 
                            text : "Êtes-vous sûr de vouloir supprimer cette tâche ?", 
                            handleYes : () => {
                                handleDelete([form])
                                props.setManager((state:ListingHoroManager) => {
                                    return {
                                        ...state, 
                                        selected_task : null
                                    }
                                })
                            }
                        }))}}
                >
                    Supprimer
                </div>

                <div  
                    className='selected-task--submit'
                    onClick = {() => {
                        dispatch(set_popup_warning({
                            status : true, 
                            text : "Êtes-vous sûr de vouloir modifier cette tâche ?", 
                            handleYes : () => handleSubmit()
                        }))}}
                >
                    Modifier
                </div>
                <div id = "task-title">
                    <input 
                        ref = {titleRef}
                        placeholder='Nom du bloc'
                        type="text" 
                        value = {isFocused ? form.name : data_form.name}
                        onChange = {(e) => setForm({
                            ...form, 
                            name : e.target.value
                        })}
                    />
                </div>

                <div id = "date_time">
                    <DateTimeManager 
                        form={data_form}
                        setForm={setForm}
                        manager={manager}
                        setManager={setManager as React.Dispatch<React.SetStateAction<DateTimeManagerMan>>}
                    />
                </div>

                <div id="tasks">
                    <TableTasks
                        server={props.server}
                        tbody={form.tasks_descriptions}
                        setTbody={setForm}
                        handleAddTask={() => setManager((state:any) => {return {...state, add_task : true}})} 
                        
                    />
                </div>
            </motion.div>
        </div>
    )
}