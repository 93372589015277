import React from "react"
import { contacts_types, ContactType, PaymentType, type_payments } from "../../../utils.clients"
import { Icons } from "tc-minibox" ; 
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { TableForProps } from "tc-minibox/dist/types/table/index.table";
import { icons_config } from "../../../../../config/context.config";
import {Table} from 'tc-minibox'
import { useDispatch } from "react-redux";
import { addRequestStatus } from "../../../../../store/global/actions.global";


export interface InformationsProps {
    client : any, 
    handleUpdate : (data:any) => void, 
}

interface InformationsManager {

}

export default function Informations(props:InformationsProps) {
    const dispatch = useDispatch()
    /**
     * 
     * {
    "_id": "65c5a8e5b613198d4c3cbe5b",
    "code": "CBRU",
    "name": "Bruno Vidéo",
    "payment_choice": "check",
    "payment_info": {
        "account_number": "",
        "bank_code": "",
        "account_holder": ""
    },
    "address": [
        "",
        "",
        ""
    ],
    "zip_code": 0,
    "contact": [],
    "deleted": false,
    "date_insert": "2023-12-17 06:44:52",
    "user": {
        "_id": "65c595f8b613198d4c3cbe47",
        "firstname": "Tys",
        "lastname": "Bulin"
    }
}
     */

    const copyToClipboard = (data:string) => {
        navigator.clipboard.writeText(data)
        .then(() => {
            dispatch(addRequestStatus({
                status : true, 
                message : "Texte copié, ajoutez-le où le temps vous mènera !"
            }))
        })
        .catch(err => {
            dispatch(addRequestStatus({
                status : false, 
                message : "Erreur de copie, le fil du temps s’est emmêlé ! Retentons."
            }))
        })
    }

    const tbody_contact = props.client.contact.map((contact:any) => {
        return {
            ...contact, 
            tc_type : contacts_types.find((ct:ContactType) => ct.name === contact.type)?.text[`fr`]
        }
    })
    // * COMPONENTS CONFIG
    const table_config:TableForProps = {
        height : 45, 
        editing : false, 
        icons : icons_config, 
        config : [
            {
                info : {
                    text : "Nom", 
                    name : "label", 
                }, 
    
                style : {
                    width : "calc(100% - 200px - 100px)",
                }
            },
            {
                info : {
                    text : "Type", 
                    name : "tc_type", 
                }, 
    
                style : {
                    width : "100px",
                }
            },
            {
                info : {
                    text : "Valeur", 
                    name : "value",
                }, 
                style : {
                    width : `250px`, 
                    tbody : {
                        textAlign : "center"
                    }
                }
            },  
        ], 
        data : tbody_contact,
        setData : null as any,
        handleDoubleClick : (tr:any) => copyToClipboard(tr.value),
    }
 
    return (
        <div className="selected-client-infos">
            <div className="selected-client-infos__header">
                <button
                    onClick = {() => props.handleUpdate(props.client)}
                >
                    Modifier les informations
                </button>
            </div>
            <div className="selected-client-infos__container">
                <div className="selected-client-infos__container--left-side">
                    <div id = "code">
                        <p>Code :</p>
                        <p>{props.client.code}</p>
                    </div>
                    <div id = "name">
                        <p>Nom :</p>
                        <p>{props.client.name}</p>
                    </div>
                    <div id = "payment_choice">
                        <p>Mode de paiement :</p>
                        <p>{`${type_payments.find((tp:PaymentType) => tp.name === props.client.payment_choice)?.text.fr}`}</p>
                    </div>
                    {
                        props.client.payment_choice === "bank_transfer" ? 
                            <div id = "payment_info">
                                <p>Informations de paiement :</p>
                                {
                                    Object.values(props.client.payment_info).join('').trim().length > 0 ? 
                                        <React.Fragment>
                                            <p>
                                                <span>IBAN : </span>
                                                <span>{props.client.payment_info.account_number.trim().length > 0 ? props.client.payment_info.account_number : "Non défini"}</span>
                                            </p>
                                            <p>
                                                <span>BIC : </span>
                                                <span>{props.client.payment_info.bank_code.trim().length > 0 ? props.client.payment_info.bank_code : "Non défini"}</span>
                                            </p>
                                            <p>
                                                <span>Titulaire du compte : </span>
                                                <span>{props.client.payment_info.account_holder.trim().length > 0 ? props.client.payment_info.account_holder : "Non défini"}</span>
                                            </p>
                                        </React.Fragment>
                                    :  
                                        <p>Aucune information enregistrée</p>
                                }
                            </div>
                        : 
                            null
                    }

                    <div id = "address">
                        <p>Adresse :</p>
                        {
                            Object.values(props.client.address).join('').trim().length > 0 ?
                                <p>
                                    {
                                        props.client.address.filter((ad:string) => ad.trim().length !== 0).map((ad:string, index:number) => (
                                            <span key = {`CLIENT-ADD-${index}`}>{ad}</span>
                                        ))
                                    }
                                </p>
                            : 
                                <p>Aucune adresse enregistrée</p>
                        }
                    </div>
                    <div id = "zip_code">
                        <p>Code postal :</p>
                        <p>{`${props.client.zip_code}`.trim().length > 0 ? props.client.zip_code : "Non défini"}</p>
                    </div>
                    <div id = "zip_code">
                        <p>Ville :</p>
                        <p>{`${props.client.city}`.trim().length > 0 ? props.client.city : "Non défini"}</p>
                    </div>
                    {
                        props.client.contact.length > 0 ? 
                            <div id = "contact">
                                <p>Contact :</p>
                                <Table {...table_config} />
                            </div>
                        : 
                            null
                    }

                </div>
                
                <div className="selected-client-infos__container--right-side">
                    <div className='summarize summarize--time' >
                        <h1>Temps</h1>
                        <div className='summarize--main'>
                            <p>20h</p>
                            <p>En novembre</p>
                        </div>
                        <div className='summarize--percentage'>
                            <Icons name = "arrow-down" mode = "duotone" color = "white" />
                            <p>5%</p>
                        </div>
                    </div>

                    <div className='summarize summarize--money'>
                        <h1>Argent</h1>
                        <div className='summarize--main'>
                            <p>10000,00€</p>
                            <p>En novembre</p>
                        </div>
                        <div className='summarize--percentage'>
                            <Icons name = "arrow-down" mode = "duotone" color = "white" />
                            <p>5%</p>
                        </div>
                    </div>



                    <div id = "Dernières tâches effectuées">

                    </div>
                </div>

            </div>
        </div>
    )
}