import { useEffect, useState } from "react";
import { ServerProps } from "../../../../config/server.config";
import BasePopup from "../../../../tools/components/general/BasePopup";
import CategorizerManager from "../../../../tools/components/general/Categorizer";
import Informations from "./grid/Informations";
import { useDispatch } from "react-redux";
import { addRequestStatus, setLoaderStatus } from "../../../../store/global/actions.global";
import ContainerLoad from "../../../../tools/components/general/ContainerLoad";
import Activity from "./grid/Activity";



export interface SelectedClientProps extends ServerProps {
    handleClose : () => void, 
    selected_client : any, 
    handleUpdate : (data:any) => void, 
}

interface SelectedClientManager {
    initialize : boolean,
}

export default function SelectedClient(props:SelectedClientProps) {
    // * GLOBAL VARIABLES 
    const dispatch = useDispatch()

    // * STATES
    const [manager, setManager] = useState<SelectedClientManager>({
        initialize : true, 
    })

    const categorizer_config = [
        {
            id : "MOD-00", 
            name : "Informations",
            svg : "id-badge", 
            component : <Informations 
                            client = {props.selected_client}
                            handleUpdate = {(data:any) => props.handleUpdate(data)}
                        />
        }, 
        {
            id : "MOD-01", 
            svg : "stopwatch", 
            name : "Activité",
            component : <Activity 
                        
                        />
        }, 
    ]

    // * USE EFFECTS

    //INITIALIZE
    /*useEffect(() => {
        if(!manager.initialize) {
            props.server.post('general/clients/get-client-info', {id_client : props.selected_client})
            .then((res:any) => {
                setManager(state => { return {...state, initialize : true}})
                setClient(res.data.data)
            })
            .catch(err => {
                  dispatch(addRequestStatus({
                status : err.response.data.status, 
                message : err.response.data.message
            }))
            })
        }
    }, [manager.initialize])*/

    /*useEffect(() => {
        if(JSON)
    })*/





    return (
        <BasePopup
            nameClass="selected-client"
            handleClose = {props.handleClose}
        >
            <ContainerLoad load = {!manager.initialize} >
                <h1>{`${props.selected_client.code} - ${props.selected_client.name}`}</h1>
                <CategorizerManager config = {categorizer_config} rerender = {true} />
            </ContainerLoad>
        </BasePopup>
    )
}