import React, { useEffect, useState } from "react";
import { ServerProps } from "../../config/server.config";
import BaseApp from "../../tools/components/general/BaseApp";
import BaseContainer from "../../tools/components/general/BaseContainer";
import { icons_config } from "../../config/context.config";
import { TableForProps } from "tc-minibox/dist/types/table/index.table";
import { Table } from "tc-minibox";
import ServiceMan from "./layouts/Manager";
import { AnimatePresence } from "framer-motion";
import { useDispatch } from "react-redux";
import { addRequestStatus, set_popup_warning, setLoaderStatus } from "../../store/global/actions.global";
import moment from "moment";




export interface ServicesProps extends ServerProps {

}

export interface ServiceManager {
    add : boolean; 
    initialize : boolean, 
    selected_data : string | null,
    update : {
        status : boolean, 
        content : any
    } 
}

const initialize_update = {
    status : false, 
    content : {}
}

export default function Services(props:ServerProps) {
    // * GENERAL VARIABLES
    const dispatch = useDispatch()

    // * STATES
    const [services, setServices] = useState<any>([])
    const [manager, setManager] = useState<ServiceManager>({
        add : false, 
        initialize : false, 
        update : initialize_update, 
        selected_data : null
    })
    
    const tbody = services.map((tb:any) => {
        return {
            ...tb, 
            tc_family : tb.family !== null ? tb.family.name : "Non défini", 
            tc_subfamily : tb.subfamily !== null ? tb.subfamily.name : "Non défini",
            tc_date_insert : moment(tb.date_insert).format("DD/MM/YYYY"),
            tc_user : tb.user ? `${tb.user.firstname} ${tb.user.lastname}` : "Non défini"
        }
    })


    useEffect(() => {
        if(!manager.initialize) {
            dispatch(setLoaderStatus({status : true, message : "Chargement des services en cours..."}))
            props.server.get('general/services/list')
            .then((res:any) => {
                setManager(state => { return {...state, initialize : true}})
                setServices(res.data.data)
                dispatch(setLoaderStatus({status : false, message : ""}))
            })
            .catch(err => {
                  dispatch(addRequestStatus({
                status : err.response.data.status, 
                message : err.response.data.message
            }))
                dispatch(setLoaderStatus({status : false}))
            })
        }
    }, [manager.initialize])

    // * FUNCTIONS 
    const handleDelete = (selected_data:any) => {
        const service_ids = selected_data.map((el:any) => el._id)
        dispatch(setLoaderStatus({status : true, message : "Suppression en cours..."}))
        props.server.post('general/services/delete', {service_ids : service_ids})
        .then((res:any) => {
            dispatch(addRequestStatus({
                status : true, 
                message : res.data.message
            }))
            setServices((state:any) => state.filter((el:any) => !service_ids.includes(el._id)))
            dispatch(setLoaderStatus({status : false, message : ""}))
        })
        .catch(err => {
            dispatch(addRequestStatus({
                status : err.response.data.status, 
                message : err.response.data.message
            }))
            dispatch(setLoaderStatus({status : false, message : ""}))
        })
    }

    // * COMPONENTS CONFIG
    const table_config:TableForProps = {
        height : 45, 
        editing : false, 
        icons : icons_config, 
        config : [
            {
                info : {
                    text : "Code", 
                    name : "code", 
                }, 
    
                style : {
                    width : "100px",
                }
            },
            {
                info : {
                    text : "Nom", 
                    name : "label", 
                }, 
    
                style : {
                    width : "calc(100% - 800px)",
                }
            },
            {
                info : {
                    text : "Catégorie", 
                    name : "tc_family",
                }, 
                style : {
                    width : `200px`, 
                    tbody : {
                        textAlign : "center"
                    }
                }
            },  
            {
                info : {
                    text : "Sous-catégorie", 
                    name : "tc_subfamily",
                }, 
                style : {
                    width : `200px`, 
                    tbody : {
                        textAlign : "center"
                    }
                }
            },  
            {
                info : {
                    text : "Date d'insertion", 
                    name : "tc_date_insert",
                }, 
                style : {
                    width : `150px`, 
                    tbody : {
                        textAlign : "center"
                    }
                }
            },  
            {
                info : {
                    text : "Inséré par", 
                    name : "tc_user",
                }, 
                style : {
                    width : `150px`, 
                    tbody : {
                        textAlign : "center"
                    }
                }
            }, 
            
        ], 
        data : tbody,
        setData : setServices,
        header : {
            buttons : {
                include : ["add", "delete"], 
                actions : {
                    add: () => setManager(state => {return {...state, add : true}}), 
                    
                    delete : (e:any) => {
                        if(e.length === 0) {
                            return dispatch(addRequestStatus({
                                status : false, 
                                message : "Veuillez sélectionner au moins un service."
                            }))
                        }

                        return dispatch(set_popup_warning({
                            status : true, 
                            text : "Êtes-vous sûr de vouloir supprimer ce(s) service(s) ?", 
                            handleYes : () => handleDelete(e)
                        }))

                    }, 

                }
            }
        }, 
        handleDoubleClick : (tr:any) => {
            const selected_client = services.find((el:any) => el._id === tr._id)
            setManager(state => {return {...state, add : true, update : {status : true, content : selected_client}}})
        
        }
    }
    
    return (
        <React.Fragment>
            <AnimatePresence>
            {
                manager.add ? 
                    <ServiceMan 
                        update = {manager.update}
                        server={props.server}
                        handleClose={() => setManager(state => {return {...state, add : false, update : initialize_update}})}
                        setData={setServices}
                    />
                : 
                    null
            }
            </AnimatePresence>
            <BaseApp>
                <BaseContainer nameClass="services">
                    <Table {...table_config}/>
                </BaseContainer>
            </BaseApp>
        </React.Fragment>
    )
}