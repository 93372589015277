/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import './styles/style.css';
import './styles/normalize.css';
import BasicTools from './tools/components/basictools/BasicTools';
import { useDispatch} from 'react-redux';
import {motion} from 'framer-motion';
import Main from './components/Main';
import axios from 'axios';
import { server_host } from './config/server.config';
import { addRequestStatus, setLoaderStatus } from './store/global/actions.global';
import { StringUtils } from 'tc-minibox';
import { set_authentification } from './store/account/actions.account';
import {ReactComponent as TwoAuthPana} from './assets/svg/illustrations/two-factor-auth-bro.svg'
import {ReactComponent as DoNotEnter} from './assets/svg/illustrations/do-not-enter-pana.svg' ; 
import { useTranslation } from 'react-i18next';

interface AppManager {
    mode : "redirect" | "checking" | "logged", 
    initialize : boolean
}

function App() {
  // * GLOBAL VARIABLES
  const dispatch = useDispatch()
  //const user = useSelector(account_user)
  const {t} = useTranslation()
  const params = new URLSearchParams(window.location.search);
  const token = params.get('token');

  // * STATES
  const [manager, setManager] = React.useState<AppManager>({
      mode : "checking", 
      initialize : false
  })

/*
  http://localhost:3000?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl91c2VyIjoiODljMTI5NzhiNTliZjVlOTQ0MzY3NTI5ZTExYmZmZjciLCJpZF91c2VyIjo5LCJpYXQiOjE2OTQ4NzE2NTd9.DFtwbelrKQ6lQirR0lRut-QpDHV9W0KeMIJKmaO2LYM
*/
  useEffect(() => {
    if(!StringUtils.isUndefinedValue(token) && !manager.initialize) {
      axios.post(server_host(`auth`), {token : token})
      .then(res => {
          
          dispatch(addRequestStatus({
              status : res.data.status, 
              message : t(res.data.message)
          }))
          dispatch(setLoaderStatus({status : false}))
          setManager(state => {return{...state, mode : "logged", initialize : true}})
          dispatch(set_authentification({
            token : res.data.token,
            infos : res.data.data
          }))
      })
      .catch(err => {
          /*setTimeout(() => {
            window.location.href = 'https://mynyomi.fr';
          }, 5000); // Délai de 5 secondes*/
          dispatch(setLoaderStatus({status : false}))
            dispatch(addRequestStatus({
                status : err.response.data.status, 
                message : t(err.response.data.message)
            }))
      })
    } else {
      setManager(state => {return{...state, mode : "redirect"}})
      setTimeout(() => {
        window.location.href = 'https://pulsify.tropicode.re';
      }, 3000); // Délai de 5 secondes
    }

  }, [])


  return (
    <React.Fragment>
      <BasicTools />
      {
          manager.mode === "checking" ?
              <motion.div 
                initial = {{scale : 0}}
                animate = {{scale : 1}}
                exit = {{scale : 0}}
                className = "checking-identity">
                <TwoAuthPana />
                <p>Vérification de votre identité...</p>
              </motion.div>
          : manager.mode === "redirect" ?
            <motion.div 
                initial = {{scale : 0}}
                animate = {{scale : 1}}
                exit = {{scale : 0}}
                className = "redirection-user"
            >
              <DoNotEnter />
              <p>Identité invalide.... Redirection en cours...</p>
            </motion.div>
          : 
            <Main />
      }
    </React.Fragment>
  );
}

export default App;
