import {motion} from 'framer-motion';
import { loader_variants } from '../basictools.animations';
import { LoaderType } from '../../../../store/types.store';


export default function Loader(props:LoaderType) {

    return (
        <motion.div 
            exit = "exit"
            initial = "initial"
            animate = "animate"
            variants = {loader_variants.background}
            className='loader'
        >
            <div>
                <div className='loader--container'>
                    <div className="loading bar">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
                {props.message ? 
                    <div className = "loader--message">
                        <p>{props.message}</p>
                    </div>
                : null}
            </div>
        </motion.div>
    )
}