import React, { useEffect } from 'react'
import { ServerProps } from "../../../config/server.config"
import SearchSelect from "../../../tools/components/general/SearchSelect"
import { HorologerManager, SaveTimeInterface } from "../Horologer"
import {AnimatePresence, motion} from 'framer-motion' ; 
import {ReactComponent as SparklesSVG} from '../../../assets/svg/icons/sparkles.svg'
import {ReactComponent as NoDataTaskSVG} from '../../../assets/svg/illustrations/no-data-tasks.svg'
import { Icons } from "tc-minibox";
import { useState } from "react";
import { calculateHoursDifference, CollectorForm, dateTimeForm, mui_sx, timeForm } from "../horologer.utils";
import { useDispatch, useSelector } from "react-redux";
import { addRequestStatus, setLoaderStatus } from "../../../store/global/actions.global";
import {ReactComponent as HorologerIcon} from '../../../assets/svg/icons/horologer.svg'
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Checkbox, Paper } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { fr } from 'date-fns/locale';
import DatePickerMui from '../../base/DatePickerMui';
import TableTasks from '../widgets/TableTasks';
import { v4 } from 'uuid';
import MainButton from '../../../tools/components/general/MainButton';
import PopupAddTask from '../widgets/PopupAddTask';
import { account_user } from '../../../store/account/selector.account';


export interface CollectorProps extends ServerProps {
   state : SaveTimeInterface, 
   setState : React.Dispatch<React.SetStateAction<SaveTimeInterface>>, 
   manager : HorologerManager, 
   setManager : React.Dispatch<React.SetStateAction<HorologerManager>>
}

interface CollectorManager {
    prompt_load : boolean, 
    mode_time : "date_time" | 'time', 
    add_task : boolean
}

export default function Collector(props:CollectorProps) {
    //* GENERAL VARIABLES
    const dispatch = useDispatch()
    const user_account = useSelector(account_user)

    const example:CollectorForm = {
        user : user_account.infos._id, 
        name : "", 
        "tasks_descriptions": [
            { id: '473eecdf-75b7-4b26-a638-879839391ea5', client: null, service: null, content: 'Service A', hours: 2 },
            { id: '77f3611a-ad96-4a70-9691-479ac7d78964', client: null, service: null, content: 'Service B', hours: 3 },
            { id: '79a0c1cc-0e7e-49fa-9e06-65d886db1596', client: null, service: null, content: 'Service C', hours: 1.5 },
            { id: 'cd8d0444-dfdf-4845-8160-43e86fe52e21', client: null, service: null, content: 'Service D', hours: 2.5 },
            { id: '38f76a80-ea13-450d-8077-cbce68e73e81', client: null, service: null, content: 'Service E', hours: 1.2 },
            { id: '4ec0f4c1-02b5-4c0a-87bb-0fff79efbe83', client: null, service: null, content: 'Service F', hours: 3.5 },
            { id: '3aa35039-9200-48fc-8b82-9976c1bb73f1', client: null, service: null, content: 'Service G', hours: 2.3 },
            { id: 'c3c504ef-2ef1-4089-b785-681e07573ffe', client: null, service: null, content: 'Service H', hours: 1.8 },
            { id: 'a4b160bd-c294-49c4-a1a6-a9c1176e6dcb', client: null, service: null, content: 'Service I', hours: 4 },
            { id: '6950a02c-6047-4ab2-a45f-3b2ea16b5079', client: null, service: null, content: 'Service J', hours: 2.7 },
            { id: 'f3d5925e-9af7-4cf2-bbc5-2ec1a0eb529b', client: null, service: null, content: 'Service K', hours: 2.1 },
            { id: 'd9bebbc2-d693-4824-85d1-f0f6b549eab4', client: null, service: null, content: 'Service L', hours: 3.2 },
            { id: '9c5ad871-92f3-40c3-9f6f-d8b1de3b8890', client: null, service: null, content: 'Service M', hours: 1.9 },
        ],    
        "date_time": {},  
        "time": {
            "total": 20,
            "date": null
        }
    }

    const initial_form_state:CollectorForm = {
        name : "", 
        tasks_descriptions : [],
        date_time : {}, 
        time : {}, 
        user : user_account.infos._id
    }

    //*USE STATES
    const [prompt, setPrompt] = useState<string>("")
    const [manager, setManager] = useState<CollectorManager>({ 
        prompt_load : false, 
        mode_time : "date_time", 
        add_task : false
    })
    
    const [form, setForm] = useState<CollectorForm>(initial_form_state/*example*/)

    const total_datetime_hours = () => {
        const dateTimeForm = form.date_time as dateTimeForm;
    
        // Vérifier si date_time n'est pas vide
        if (dateTimeForm && typeof dateTimeForm === 'object') {
            // Vérifier si start et end existent
            if (dateTimeForm.start && dateTimeForm.end) {
                // Vérifier si start et end sont des objets Date valides
                if (dateTimeForm.start instanceof Date && dateTimeForm.end instanceof Date) {
                    // Conversion de la différence en heures
                    const hoursDiff = calculateHoursDifference(dateTimeForm.start, dateTimeForm.end);
    
                    return hoursDiff; // Retourner le résultat
                } else {
                    // Les dates de début et de fin ne sont pas des objets Date valides
                    return null;
                }
            } else {
                // Les propriétés start et end ne sont pas définies
                return null;
            }
        } else {
            // date_time est vide ou n'est pas un objet
            return null;
        }
    }
    const total_task_description = form.tasks_descriptions.length > 0 ? form.tasks_descriptions.map((task) => `${task.hours}` !== "" ? task.hours : 0).reduce((a, b) => parseFloat(a as any) + parseFloat(b as any), 0) : null
    const rest_hours = () => {
        
        if(manager.mode_time === "date_time") {
            if(total_datetime_hours() !== null && total_task_description !== null) {
                return total_datetime_hours() as number - parseFloat(`${total_task_description}`)
            }
            if(total_task_description === null) {
                return total_datetime_hours() as number
            }
            return null
        }
        if(!(form.time as timeForm).total || (form.time as timeForm).total as any === "") {

            return null
        }

        if(total_task_description === null) {
            return (form.time as timeForm).total
        }

        
        return total_task_description !== null ? parseFloat(`${total_task_description}`) - (form.time as timeForm).total : null
        
    }


    const handleSubmitAi = () => {

        if(prompt.trim().length === 0) {
            return dispatch(addRequestStatus({
                status : false, 
                message : "Ah ! Le vide. Ajoute des détails pour que ça fonctionne."
            }))
        }

        setManager((state:any) => {return {...state, prompt_load : true}})
        return props.server.post('/ai/horologer/extract-tasks', {prompt : prompt}) 
        .then((res:any) => {
            setManager((state:any) => {return {...state, prompt_load : false}})
            setPrompt("")
            //dispatch(addRequestStatus(res.data))
            if(Object.values(res.data).length === 0) {
                return dispatch(addRequestStatus({
                    status : false, 
                    message : "Oh non ! Je n'ai pas trouvé d'informations à extraire. Réessaye !"
                }))
            }

            if(res.data.time !== null) {
                setManager((state:any) => {return {...state, mode_time : "time"}})
            }

            setForm((state:any) => {return {
                ...state, 
                tasks_descriptions : res.data.tasks_description.map((task:any) => {
                    return {
                        ...task, 
                        id : v4(),
                        service : null, 
                        client : null
                    }
                }), 
                date_time : res.data.date_time !== null ? {
                    start : new Date(res.data.date_time.start), 
                    end : new Date(res.data.date_time.end)
                } : {},
                time : res.data.time !== null ? {
                    total : res.data.time.total, 
                    date : new Date(res.data.time.date)
                
                }: {}
            }})
        })
        .catch(err => {
            dispatch(addRequestStatus(err.response.data))
            setManager((state:any) => {return {...state, prompt_load : false}})
        })
    }

    const handleSubmit = () => {

        if(manager.mode_time === "date_time") {
            if(total_datetime_hours() === null){
                return dispatch(addRequestStatus({
                    status : false, 
                    message : "Veuillez définir une date et une heure valide"
                }))
            } 

            if(total_datetime_hours() === 0) {
                return dispatch(addRequestStatus({
                    status : false, 
                    message : "Le total d'heures entre la date de début et la date de fin ne peut pas être égal à 0"
                }))
            }

            if(total_task_description !== total_datetime_hours()) {
                return dispatch(addRequestStatus({
                    status : false, 
                    message : "Le total d'heures entre les missions et la date de début et de fin ne sont pas égaux"
                }))
            }
        }

        if(manager.mode_time === "time") {
            if((!(form.time as timeForm).date || !(form.time as timeForm).total || [null, 0].includes((form.time as timeForm).total) || (form.time as timeForm).date === null)) {
                return dispatch(addRequestStatus({
                    status : false, 
                    message : "Veuillez définir une date et une temps d'heure valide"
                }))
            }
            if(parseFloat(`${total_task_description}`) !== parseFloat((form.time as timeForm).total as any)) {
                return dispatch(addRequestStatus({
                    status : false, 
                    message : "Le total d'heures entre les missions et le total d'heures ne sont pas égaux"
                }))
            }
        }

        if([null, 0].includes(total_task_description)) {
            return dispatch(addRequestStatus({
                status : false, 
                message : "Veuillez définir des missions"
            }))
        }

        if(form.tasks_descriptions.map((task:any) => task.client).includes(null)) {
            return dispatch(addRequestStatus({
                status : false, 
                message : "Veuillez définir un client pour chaque mission"
            }))
        }

        if(form.tasks_descriptions.map((task:any) => task.service).includes(null)) {
            return dispatch(addRequestStatus({
                status : false, 
                message : "Veuillez définir un service pour chaque mission"
            }))
        } 
 
        if(form.tasks_descriptions.map(el => ['', 0].includes(el.hours) ? 0 : el.hours).includes(0)) {
            return dispatch(addRequestStatus({
                status : false, 
                message : "Veuillez définir des heures pour chaque mission"
            })) 
        }

        var formContainer = form

        if(manager.mode_time === "date_time") {
            formContainer = {
                ...form, 
                time : {}
            }
        } else {
            formContainer = {
                ...form, 
                date_time : {}
            }
        }

        formContainer = {
            ...formContainer, 
            tasks_descriptions : form.tasks_descriptions.map((task:any) => {
                return {
                    ...task, 
                    client : task.client._id, 
                    service : task.service._id
                }
            }), 

        }

        dispatch(setLoaderStatus({status : true}))

        
        props.server.post('general/agenda/add', formContainer)
        .then(res => {
            setForm(initial_form_state)
            dispatch(addRequestStatus({
                status : true, 
                message : res.data.message
            }))
            dispatch(setLoaderStatus({status : false}))
        })
        .catch(err => {
                dispatch(addRequestStatus({
            status : err.response.data.status, 
            message : err.response.data.message
        }))
            dispatch(setLoaderStatus({status : false}))
        })




    }

    useEffect(() => {
        if(Object.values(props.manager.stopwatch_result).length > 0) {
            setForm((state:any) => {
                return {
                    ...state, 
                    time : {
                        total : props.manager.stopwatch_result.hours, 
                        date : props.manager.stopwatch_result.date
                    }
                }
            })
            setManager((state:any) => {return {...state, mode_time : "time"}})
        } 
    }, [props.manager.stopwatch_result])

    return (
        <React.Fragment>
            <AnimatePresence>
            {
                props.manager.collector ? 
                    <motion.div 
                        exit={{opacity : 0}}
                        initial={{opacity : 0}}
                        animate={{opacity : 1}}
                        className='collector--background'
                        onClick={() => props.setManager((state:any) => {return {...state, collector : false}})}
                    ></motion.div>
                : 
                    null
            }
            </AnimatePresence>

            <div className="collector" style = {props.manager.collector ? {left : 0} : {left : -600}}>
                {
                    manager.add_task ? 
                        <PopupAddTask 
                            server={props.server}
                            handleClose={() => setManager((state:any) => {return {...state, add_task : false}})}
                            setTbody={setForm}
                        /> 
                    : 
                        null
                }
                <div>
                    <div>
                        <div 
                            onClick={() => props.setManager((state:any) => {return {...state, collector : !state.collector}})}
                            className='collector--tab'
                        >
                            {
                                props.manager.collector ? 
                                    `Fermer`
                                : 
                                    `Collecteur`
                            }
                        </div>
                        <AnimatePresence>
                        {
                            manager.prompt_load ? 
                                <motion.div 
                                exit={{
                                    opacity: 0
                                }}
                                initial={{
                                    opacity: 0
                                }}
                                animate={{
                                    opacity: 1
                                }}
                                className="horologer_loader"
                            >
                                <div>
                                    <HorologerIcon />
                                    <div className="horologer_loader--dots">
                                        <span>.</span><span>.</span><span>.</span>
                                    </div>
                                    <div className="horologer_loader--thinking">
                                        Je traite la demande
                                    </div>

                                </div>
                                </motion.div>
                            : 
                                null
                        }
                        </AnimatePresence>
                        <h1>Collecteur</h1>

                        <div className='collector--reset' onClick={() => setForm(initial_form_state)}>
                            <Icons name = "arrows-repeat" color = "#6edf90" />
                        </div>
    
                        <h2>
                            <div className='pointy-div'><SparklesSVG /></div>
                            <div className='subtitle'>Assistant intelligent</div>
                        </h2>
                        <div className="collector__ai">
                            <div className="sparkles">
                                <SparklesSVG />
                            </div>
                            <textarea
                                placeholder="Demander à l'IA"
                                value={prompt}
                                onChange={(e) => setPrompt(e.target.value)}
                            ></textarea>
                            <div className='collector__ai--submit' onClick={handleSubmitAi}>
                                <Icons name = "paper-plane-top" color = "#6edf90" />
                            </div>

                        </div>
                        <h2>
                            <div className='pointy-div'>1</div>
                            <div className='subtitle'>Intitulé du bloc de tâche</div>
                        </h2>
                        <div className='collector__name'>
                            <TextField
                                label = "Nom du bloc"
                                variant="outlined"
                                value = {form.name}
                                onChange={(e) => {
                                    setForm((state:any) => {return {...state, name : e.target.value}})
                                }}
                                sx={mui_sx} 
                            />                  
                        </div>
                        <h2>
                            <div className='pointy-div'><p>2</p></div>
                            <div className='subtitle'>Définissez le moment</div>
                        </h2>
                        <div className="collector__datetime">

                            <div className="collector__datetime--mode-time">
                                <div
                                    id = {`${manager.mode_time === "date_time" ? "mode-time--active" : {}}`}
                                    onClick = {() => setManager((state:any) => {return {...state, mode_time : "date_time"}})}
                                >
                                    Date et heure
                                </div>
                                <div
                                    id = {`${manager.mode_time === "time" ? "mode-time--active" : {}}`}
                                    onClick = {() => setManager((state:any) => {return {...state, mode_time : "time"}})}
                                >
                                    Heure
                                </div>
                            </div>

                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
                                <div className="collector__datetime--choose-date">
                                    {
                                        manager.mode_time === "date_time" ? 
                                                <React.Fragment>
                                                    <DatePickerMui 
                                                        label = "Date de début"
                                                        value = {Object.keys(form.date_time).length > 0 ? (form.date_time as dateTimeForm).start : null}
                                                        onChange = {(newValue) => {
                                                            setForm((state:any) => {return {...state, date_time : {...state.date_time, start : newValue}}})
                                                        }}
                                                        disableDateAfter={(form.date_time as dateTimeForm).end} 
                                                    />
                                                    {
                                                        Object.keys(form.date_time).length > 0 ? 
                                                            <DatePickerMui
                                                            label = "Date de fin"
                                                            value = {Object.keys(form.date_time).length > 0 ? (form.date_time as dateTimeForm).end : null}
                                                            onChange = {(newValue) => {
                                                                setForm((state:any) => {return {...state, date_time : {...state.date_time,end : newValue}}})
                                                            }}
                                                            disableDateBefore={(form.date_time as dateTimeForm).start} 
                                                        /> 
                                                        : null
                                                    }
                           
                                                </React.Fragment>
                                            : 
                                                <React.Fragment>
                                                        <DatePicker
                                                            label = "Date"
                                                            value = {Object.keys(form.time).length > 0 ? (form.time as timeForm).date : null}
                                                            onChange = {(newValue) => {
                                                                setForm((state:any) => {return {...state, time : {...state.time, date : newValue}}})
                                                            }}
                                                            sx={mui_sx} 
                                                        /> 
                                                        <TextField
                                                            label = "Total heures"
                                                            variant="outlined"
                                                            value = {Object.keys(form.time).length > 0 ? (form.time as timeForm).total : ''}
                                                            onChange={(e) => {
                                                                setForm((state:any) => {return {...state, time : {...state.time, total : e.target.value}}})
                                                            }}
                                                            sx={mui_sx} 
                                                        />
                                                        

                                                </React.Fragment>
                                    }
                                </div>
                            </LocalizationProvider>
                        </div>
                    
                        <h2>
 
                            <div className='pointy-div'><p>3</p></div>

                            {
                                rest_hours() !== null ?
                                    <p className='time-rest'>
                                        {`${rest_hours()}h restantes à affecter`}
                                    </p>
                                : 
                                    null 
                            }
                            <div className='subtitle'>Missions du jour</div>
                            


                        </h2>

                        <div className='collector__tasks'>
                            <TableTasks 
                                server={props.server}
                                tbody={form.tasks_descriptions}
                                setTbody={setForm}
                                handleAddTask={() => setManager((state:any) => {return {...state, add_task : true}})} 
                                
                            />
                        </div> 

                        <MainButton
                            nameClass='collector__submit'
                            handleClick = {() => handleSubmit()}
                        >
                            Enregistrer
                        </MainButton>

                    </div> 
                </div>
            </div> 
        </React.Fragment>
    )
}
