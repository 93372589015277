import { stepsText_position } from "../../config/context.config";
import BaseApp from "../../tools/components/general/BaseApp";
import BaseContainer from "../../tools/components/general/BaseContainer";
import {ReactComponent as HorologerIcon} from '../../assets/svg/icons/horologer.svg'
import StepsText from "../../tools/components/general/StepsText";
import Collector from "./layouts/Collector";
import ListingHoro from "./layouts/ListingHoro";
import { AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";
import Stopwatch from "./layouts/Stopwatch";
import {motion} from 'framer-motion'
import { ServerProps } from "../../config/server.config";
import { ClientModel } from "../../database/client-model.db";
import { useDispatch } from "react-redux";
import { addRequestStatus, setLoaderStatus } from "../../store/global/actions.global";


export interface HorologerProps extends ServerProps {

}

export interface HorologerManager {
    initialize : boolean, 
    stopwatch_popup : boolean, 
    collector : boolean,
    stopwatch_result : {
        date : Date, 
        hours : number
    } | any
}

export interface SaveTimeInterface {
    id_client : any, 
    date : Date
}

export default function Horologer(props:HorologerProps) {
    // * GENERAL VARIABLES 
    const dispatch = useDispatch()
    
    //* STATES
    const [manager, setManager] = useState<HorologerManager>({
        initialize : false,
        stopwatch_popup : false, 
        collector : false, 
        stopwatch_result : {}
    })
    const [newSavedTime, setNewSaveTime] = useState<SaveTimeInterface>({
        id_client : "all", 
        date : new Date()
    })
    const [tasks, setTasks] = useState<any[]>([])

    

    //INITIALIZE
    useEffect(() => {
        if(!manager.initialize) {
            dispatch(setLoaderStatus({status : true, message : "Chargement des tâches en cours..."}))
            props.server.get('general/agenda/list')
            .then((res:any) => {
                setManager(state => { return {...state, initialize : true}})
                setTasks(res.data.data)
                dispatch(setLoaderStatus({status : false, message : ""}))
            })
            .catch(err => {
                  dispatch(addRequestStatus({
                status : err.response.data.status,  
                message : err.response.data.message
            }))
                dispatch(setLoaderStatus({status : false}))
            })
        }
    }, [manager.initialize])

    
    return (
        <BaseApp>
            <AnimatePresence>
                {
                    manager.stopwatch_popup ? 
                        <Stopwatch 
                            manager = {manager}
                            setManager={setManager}
                            handleClose={() => setManager((state:any) => {
                                return {
                                    ...state,
                                    stopwatch_popup : false
                                }
                            })}
                        />
                    : 
                        null
                }
            </AnimatePresence>
            <BaseContainer nameClass="horologer">
                <StepsText
                    title = {"Horologer"}
                    position = {stepsText_position}
                /> 
                <Collector 
                    server={props.server}
                    state = {newSavedTime}
                    setState = {setNewSaveTime}
                    manager = {manager}
                    setManager = {setManager}
                />

                <ListingHoro 
                    server={props.server}
                    state = {newSavedTime}
                    setState = {setNewSaveTime}
                    data = {tasks}
                    setData = {setTasks}
                /> 
                
                <motion.div 
                    whileHover={{scale : 1.05}}
                    whileTap={{scale : 0.8}}
                    onClick = {() => setManager((state:any) => {
                        return {
                            ...state,
                            stopwatch_popup : true
                        }
                    })}
                    className="horologer--stopwatch">
                    <HorologerIcon />
                </motion.div>

            </BaseContainer>
        </BaseApp>
    )
}