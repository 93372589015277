import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer, View } from 'react-big-calendar';
import moment from 'moment';
import {AnimatePresence, motion} from 'framer-motion' ; 
import 'moment/locale/fr'; // Import French locale
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { SaveTimeInterface } from '../Horologer';
import {ReactComponent as ArrowLeft} from '../../../assets/svg/icons/arrow-left-solid.svg';
import {ReactComponent as ArrowRight} from '../../../assets/svg/icons/arrow-right-solid.svg';
import { ServerProps } from '../../../config/server.config';
import { DatePicker } from 'tc-minibox';
import SelectedTask from '../widgets/SelectedTask';

// Configure Moment.js to use the French locale
moment.locale('fr');

const localizer = momentLocalizer(moment);

interface ListingHoroProps extends ServerProps {
  state: SaveTimeInterface;
  setState: React.Dispatch<React.SetStateAction<SaveTimeInterface>>;
  data : any[], 
  setData : React.Dispatch<React.SetStateAction<any[]>>
}

export interface ListingHoroManager {
  selected_task : object | null, 
}

function convertDateTimeFormatCalendar(chaineDate:string) {
  // Parsez la chaîne de caractères en objet Date
  const date = new Date(chaineDate);



  // Extraction des composants de la date en UTC
  const annee = date.getUTCFullYear();
  const mois = date.getUTCMonth() + 1; // getUTCMonth() renvoie un mois de 0 à 11
  const jour = date.getUTCDate();
  const heures = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const secondes = date.getUTCSeconds();

  // Formatage de chaque composant pour assurer deux chiffres
  const moisFormate = mois < 10 ? `0${mois}` : mois;
  const jourFormate = jour < 10 ? `0${jour}` : jour;
  const heuresFormate = heures < 10 ? `0${heures}` : heures;
  const minutesFormate = minutes < 10 ? `0${minutes}` : minutes;
  const secondesFormate = secondes < 10 ? `0${secondes}` : secondes;

  // Assemblage de la nouvelle chaîne de caractères
  return `${annee}-${moisFormate}-${jourFormate}T${heuresFormate}:${minutesFormate}:${secondesFormate}`;
}

const ListingHoro: React.FC<ListingHoroProps> = (props) => {
  // * VARIABLES
  const customMessages = {
    noEventsInRange: "Aucun événement dans cette période", // Votre texte personnalisé
    // Ajoutez ici d'autres messages personnalisés si nécessaire
  };
  
 // * STATES
  const [manager, setManager] = useState<ListingHoroManager>({
      selected_task : null
  })

  const [date, setDate] = useState<Date>(new Date());
  const [view, setView] = useState<View>('month');

  const handleNavigate = (newDate: Date) => {
    setDate(newDate);
    props.setState({ ...props.state, date: newDate });
  };

  const handleViewChange = (newView: View) => {
    setView(newView);
  };

  // * FUNCTIONS
  const getAnimationProps = (isViewSelected: string) => {
    return !isViewSelected ? {
      whileHover: { scale: 1.1 },
      whileTap: { scale: 0.9 }
    } : {};
  }

  const events = props.data.map((task:any, index:number) => {
    const event_name = task.name.replaceAll(" ", "") !== "" ? task.name : task.tasks_descriptions.map((task_description:any) => task_description.content).join(', ');
    const date = () => {
      if(!task.date_time.start ) {
        return convertDateTimeFormatCalendar(task.time.date);
      }

      return task.date_time;
    }

    const colors = [
      {
        "bgColor": "#6edf90",
        "textColor": "#ffffff", 
      }, 
      {
        "bgColor": "#89fcac",
        "textColor": "#060606", 
      }, 

    ]

    return {
        "_id": task._id,
        "title": `${task.tasks_descriptions.reduce((acc:any, task_description:any) => acc + task_description.hours, 0)}h - ${event_name}`,
        "start": typeof date() === 'object' && date() !== null ? moment(task.date_time.start).toDate() : date(),
        "end": typeof date() === 'object' && date() !== null ? moment(task.date_time.end).toDate() : date(),
        "allDay": typeof date() === 'object' && date() !== null ? false : true,
        "resource": {
            "location": "Lieu 5",
            "description": "Description de l'événement spécial 1",
            "important": true,
            "organizer": "Organisateur 5"
        },

    }
      /*
        {
    "_id": "660a45bef37934f8195c4685",
    "name": "Mon bloc",
    "tasks_descriptions": [
          {
              "id": "70e2abb1-65ca-49e5-95a0-a09a5ad449a9",
              "hours": 5,
              "content": "danse",
              "service": "6607bd1dd2b0b5e6b8d82022",
              "client": "65c5a8e5b613198d4c3cbe59",
              "_id": "660a45bef37934f8195c4686"
          },
          {
              "id": "c4d4b258-a9fe-42a4-8733-cccb2234a21d",
              "hours": 3,
              "content": "chant",
              "service": "66003e199ed4299a392811b4",
              "client": "65c5a8e5b613198d4c3cbe59",
              "_id": "660a45bef37934f8195c4687"
          }
      ],
      "date_time": {
          "_id": "660a45bef37934f8195c4688"
      },
      "time": {
          "total": 8,
          "date": "2024-04-01T05:26:08.935Z",
          "_id": "660a45bef37934f8195c4689"
      },
      "user": "65c595f8b613198d4c3cbe47",
      "deleted": false,
      "date_insert": "2024-04-01T05:27:26.393Z",
      "createdAt": "2024-04-01T05:27:26.403Z",
      "updatedAt": "2024-04-01T05:27:26.403Z",
      "__v": 0
  }
      
      */

  })

  const CustomToolbar: React.FC<any> = ({ label, onNavigate, onView }) => {
    const isViewSelected = (currentView: string) => view === currentView ? 'selected-view' : '';

    // Fonction pour formater la plage de dates pour la vue semaine
    const formatWeekRange = (label: string) => {
      // Séparation du mois et des jours
      const [month, dayRange] = label.split(' ');
      const [startDay, endDay] = dayRange.split('–').map(day => day.trim());
      
      // Année actuelle (peut nécessiter un ajustement si la semaine chevauche l'année)
      const currentYear = moment().year();
    
      // Construire les dates complètes
      const startDate = moment(`${month} ${startDay}, ${currentYear}`, 'MMMM DD, YYYY');
      const endDate = moment(`${month} ${endDay}, ${currentYear}`, 'MMMM DD, YYYY');
    
      // Formatage conditionnel
      if (startDate.month() === endDate.month()) {
        return `${startDate.format('DD')} - ${endDate.format('DD MMMM')}`;
      }
      return `${startDate.format('DD MMM')} - ${endDate.format('DD MMMM')}`;
    };

    const isTodaySelected = () => {
      const today = moment().startOf('day'); // Aujourd'hui à minuit
      const selected = moment(date).startOf('day'); // La date sélectionnée à minuit
      return today.isSame(selected);
    };

    // OTHER VIEWS (MONT YWEEK DAY AGENDA)
    /* 
      <div className='toolbar-container--modes'>
        <motion.button 
          className={isViewSelected('month')} 
          onClick={() => onView('month')}
          {...getAnimationProps(isViewSelected('month'))}
        >
          Mois
        </motion.button>
        <motion.button 
          className={isViewSelected('week')} 
          onClick={() => onView('week')}
          {...getAnimationProps(isViewSelected('week'))}
        >
          Semaine
        </motion.button>
        <motion.button 
          className={isViewSelected('day')} 
          onClick={() => onView('day')}
          {...getAnimationProps(isViewSelected('day'))}
        >
          Jour
        </motion.button>
        {
          /*
          
                <motion.button 
          className={isViewSelected('agenda')} 
          onClick={() => onView('agenda')}
          {...getAnimationProps(isViewSelected('agenda'))}
        >
          Agenda
        </motion.button>
        
      

      </div>
    */

    return (
      <div className="toolbar-container">
        {
          !isViewSelected('agenda') ? 
            <React.Fragment>
              {
                isViewSelected('day') && !isTodaySelected() ?
                  <motion.button 
                    {...getAnimationProps("today")}
                    className = "toolbar-container--today" 
                    onClick={() => onNavigate('TODAY')}
                  >
                    Aujourd'hui
                  </motion.button>
               : 
                null
              }

              <div className="toolbar-container--date-picker">
                <motion.button 
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  className = "left-right" 
                  onClick={() => onNavigate('PREV')}
                >
                    <ArrowLeft />
                </motion.button>
                {
                  isViewSelected('month') ? 
                    <div className="date-picker-container">{label}</div>
                  : isViewSelected('week') ?
                    <div className="date-picker-container">{formatWeekRange(label)}</div>
                  : 
                    <div 
                      className="date-picker-container"
                    >
                      <DatePicker
                        selected={date} 
                        onChange={(newDate: Date) => handleNavigate(newDate)} 
                        dateFormat="d MMMM yyyy" 
                      />
                    </div>
                }
                <motion.button 
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  className = "left-right" 
                  onClick={() => onNavigate('NEXT')}
                >
                  <ArrowRight/>
                </motion.button>
              </div>
            </React.Fragment>
          : 
            null
        }




      </div>
    );
  };

  /*useEffect(() => {
      if(props.data.length > 0) {
        setManager({ selected_task : props.data[0] })
      }
  }, [props.data])*/

  return (
    <React.Fragment>
      <AnimatePresence>
      {
        manager.selected_task !== null ? 
            <SelectedTask 
                server={props.server}
                manager={manager}
                setManager={setManager}
                setData={props.setData}
            />
        : 
            null
      }
      </AnimatePresence>
      <div className="listing-horo">
        
        <Calendar
          localizer={localizer}
          startAccessor="start"
          endAccessor="end"
          date={date}
          view={view}
          onNavigate={handleNavigate}
          onView={handleViewChange}
          style={{ height: 500 }}
          messages = {customMessages}
          events={events}
          components={{
            toolbar: CustomToolbar
          }}
          onSelectEvent={(event:any) => {
            setManager({ selected_task: props.data.find((dt:any) => dt._id === event._id) });
          }}
          onDoubleClickEvent={(event) => {
            //console.log(event)
          }}
          
        />
      </div>
    </React.Fragment>
  );
}

export default ListingHoro;
