export interface SaveTimeInterface {
    id_client: any;
    date: Date;
}

export type tasksForm = {
  id : string, 
  hours : number, 
  content : string, 
  service : null | any, 
  client : null | any, 
}

export type dateTimeForm = {
  start : Date, 
  end : Date
}

export type timeForm = {
  total : number, 
  date : Date
}

export interface CollectorForm {
  user : string, 
  name : string, 
  tasks_descriptions : tasksForm[],
  date_time : {} | dateTimeForm, 
  time : {} | timeForm
}

export const mui_sx = { 
  '.MuiInputBase-root' : {
      height: 40, 
  }, 
  '.MuiInputBase-input': { 
  height: 50, 
  padding: '5px 14px', 
  fontSize: '0.875rem' // Taille de la police ajustée
  }, 
  '.MuiInputLabel-root': { 
      height: 50, 
  lineHeight: '1rem', // Ajustez selon vos besoins
  top: '-5px', // Ajustez la position de l'étiquette si nécessaire
  color: '#FFFFFF', // Couleur du label en focus
  display : "flex"

  }, 
  '.MuiInputLabel-shrink': { 
      color: 'white', // Couleur du label en focus
      top: '5px!important', // Ajustez la position de l'étiquette si nécessaire
  },

  '.MuiInputLabel-root.Mui-focused': { 
      color: '#6edf90', // Couleur du label en focus
      top: '5px!important', // Ajustez la position de l'étiquette si nécessaire
  },
  '& .MuiOutlinedInput-root': {
      // Bordure en non focus
      '& fieldset': {
      borderColor: 'white',
      },
      // Bordure en hover (non focus)
      '&:hover fieldset': {
      borderColor: '#6edf90',
      },
      // Bordure en focus
      '&.Mui-focused fieldset': {
      borderColor: '#6edf90',
      },
  },

}

export const calculateHoursDifference = (start: Date | null, end: Date | null): number | null => {
  // Vérifier si start et end existent et sont des objets Date valides
  if (start && end && start instanceof Date && end instanceof Date) {
      // Calcul de la différence entre les deux dates en millisecondes
      const timeDiff = end.getTime() - start.getTime();

      // Conversion de la différence en heures
      const hoursDiff = timeDiff / (1000 * 3600); // 1 heure = 1000 millisecondes * 60 secondes * 60 minutes

      return hoursDiff; // Retourner le résultat
  } else {
      // Les dates de début et/ou de fin ne sont pas valides
      return null;
  }
}