import { compagny} from '../../config/context.config';
import {motion} from 'framer-motion' ; 
import {Link, useLocation} from 'react-router-dom'
import { useSelector } from "react-redux";
import { account_user } from '../../store/account/selector.account';
import { navigation_data } from './data.nav';
import { ServerProps } from '../../config/server.config';
import {ReactComponent as BarFilter} from '../../assets/svg/icons/bars-filter-solid.svg' ; 
import { Icons } from 'tc-minibox';
import { useTranslation } from 'react-i18next';
import { MainManager } from '../Main';
import React from 'react';


export interface NavigationProps extends ServerProps {
    manager : any, 
    setManager : React.Dispatch<React.SetStateAction<MainManager>>
}

export default function Navigation(props:NavigationProps) {
    const account = useSelector(account_user)
    const location = useLocation()
    const {i18n} = useTranslation()

    return (
        <React.Fragment>
            <div 
                className='navigation--icon-menu'
                onMouseEnter={() => props.setManager({...props.manager, navigation : true})}
            >
                {
                    !props.manager.navigation ? 
                        <div><BarFilter /></div>
                    :   
                        null
                }
            </div>
            <motion.div 
                exit = "exit"
                animate = "animate"
                initial = "initial"
                className="navigation"
                style = {props.manager.navigation ? {left : "0px"} : {left : "-300px"}}
                onMouseEnter={() => props.setManager({...props.manager, navigation : true})}
                onMouseLeave={() => props.setManager({...props.manager, navigation : false})}
            >


                {/* /// LOGO /// */}
                <div className="logo">
                    {compagny.logo}
                </div>

                {/* /// LISTING NAVIGATION /// */}
                <div 
                    className="navigation__container"
                >
                    {
                        navigation_data({account : account, server : props.server, lang : i18n.language}).map(el => (
                            <div 
                                key = {el.id}
                                onClick={() => props.setManager({...props.manager, navigation : false})}
                            >
                                <Link className={location.pathname === el.path ? "active" : ""} to = {el.path} >
                                    <Icons name = {el.svg} mode = "plain" color = {location.pathname === el.path ? "#4bb86c" : "white"}/>
                                    <p style = {{color : location.pathname === el.path ? "#4bb86c" : "white"}}><span>{el.content}</span></p>
                                </Link>
                            </div>
                        ))
                    }
                </div>
            </motion.div>
        </React.Fragment>
    )
}
