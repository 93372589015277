/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState} from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Icons, StringUtils } from 'tc-minibox';
import { Axios } from 'axios';
import MiniLoad from './MiniLoad';
import { useDispatch } from 'react-redux';
import { setLoaderStatus } from '../../../store/global/actions.global';
import { useTranslation } from 'react-i18next';

type ListItem = {
    text: string;
    id: string | number, 
    [key:string] : any
};


interface SearchSelectProps {
    adding?: boolean;
    handleAdd?: (e:string) => void;
    no_data_text?: string;
    handleClick : (e:ListItem) => void;
    handleDelete : () => void;
    value : any; 
    server : {
        route: string;
        axios : Axios; 
        body? : {[key:string] : any}
    }, 
}

export const submenu_variants = {
    exit : {height : 0, opacity : 0}, 
    animate : {height : 'auto', opacity : 1}, 
    initial : {height : 0, opacity : 0}
}

export default function SearchSelect(props:SearchSelectProps) {
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const [search, setSearch] = useState({
        open: false,
        status: "resolve",
        input: "",
        list: [] as ListItem[]
    });

    const [manager, setManager] = useState({
        initialize : false
    })

    const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        if(e.target.value.length === 0) {
            props.handleDelete()
            return setSearch(state => ({
                ...state,
                open: false,
                status: "resolve",
                input: "",
                list: []
            }))
        }

        return setSearch(state => ({
            ...state,
            open: e.target.value.length > 0,
            status: "pending",
            input: e.target.value,
        }));
    }

    const handleUnfocus = () => {
        let input = "";
        if (!StringUtils.isUndefinedValue(props.value)) {
            input = search.list.find(e => e.id === props.value)?.text || "";
        }
        setSearch(state => ({
            ...state,
            open: false,
            input : input
        }));
    }

    const handleBlur = () => {
        if (search.input.length === 0 && !StringUtils.isUndefinedValue(props.value)) {

        }
    }

    const handleClick = (e:ListItem) => {
        setSearch(state => ({
            ...state,
            status: "resolve",
            open: false,
            input: e.text
        }));
        props.handleClick(e);
    }


    useEffect(() => {
        const timer = setTimeout(() => {
            if (search.status === "pending") {

                return props.server.axios.post(props.server.route, { search_string: search.input, ...props.server.body})
                .then((res: any) => {
                    var data_list = res.data.data;

                    if (props.value) {
                        data_list = data_list.filter((e: any) => e.id !== props.value);
                    }

                    setSearch(state => ({
                        ...state,
                        status: "resolve",
                        list: data_list
                    }));
                })
                .catch(() => {
                    setSearch(state => ({
                        ...state,
                        status: "resolve",
                        list: []
                }));
                });
            }
        }, 500);

        return () => {
            clearTimeout(timer);
        };
    }, [search.input]);

    useEffect(() => {
        if(!StringUtils.isUndefinedValue(props.value) && search.input.length === 0) {
            dispatch(setLoaderStatus({status : true}))
            props.server.axios.post(props.server.route, { search_string: search.input, ...props.server.body })
            .then((res: any) => {
                dispatch(setLoaderStatus({status : false}))
                setSearch(state => ({
                    ...state,
                    input : res.data.data.find((e:any) => e.id === props.value)?.text || "",
                }));
            })
            .catch(() => {
                dispatch(setLoaderStatus({status : false}))
            });
        }
    },[search.input, props.value])



    useEffect(() => {
        if(!StringUtils.isUndefinedValue(props.value) && !manager.initialize) {
            props.server.axios.post(props.server.route, { search_string: " ", ...props.server.body})
            .then((res: any) => {
                setManager(state => ({...state, initialize : true}))
                setSearch(state => ({
                    ...state,
                    status: "resolve",
                    input : res.data.data.find((e:any) => e.id === props.value)?.text || "",
                }));
            })
            .catch(() => {
                setSearch(state => ({
                    ...state,
                    status: "resolve",
                    list: []
                }));
            });
        }
    }, [])

    return (
        <div id='search-select'>
            <Icons name={'magnifying-glass'} mode="duotone" color="grey" />
            <input
                type="text"
                name="search"
                value={search.input}
                placeholder={"Tapez pour chercher"}
                autoComplete="off"
                onBlur={handleBlur}
                onChange={handleChangeSearch}
            />
            {search.open && <div className='list--background' onClick={handleUnfocus}></div>}
            <AnimatePresence>
                {search.open &&
                    <motion.div {...submenu_variants} className='list'>
                        {props.adding && !search.list.find(ls => StringUtils.undress_string(ls.name) === StringUtils.undress_string(search.input)) && search.input.replaceAll(' ', '').length > 0 ?
                            <div className='add' onClick={() => {
                                if(props.handleAdd) {
                                    props.handleAdd(search.input)
                                    setSearch(state => ({
                                        ...state,
                                        open : false, 
                                    }))
                                }
                            }}>
                                <Icons name="plus" />
                                <p>{search.input}...</p>
                            </div>
                            : null}
                        {search.status === "resolve" ?
                            <React.Fragment>
                                {
                                    search.input.replaceAll(' ', '').length > 0 ? 
                                        search.list.length > 0  ?
                                        search.list.map(sl => (
                                            <div className='list--element' onClick={() => handleClick(sl)} key={`SEARCH-SEL-LIST-ELMT${sl.id}`}>
                                                <p>{sl.text}</p>
                                            </div>
                                        ))
                                        : <div className='list--nodata'>{props.no_data_text}</div>
                                    : 
                                        null
                                }
                            </React.Fragment>
                            : <MiniLoad />}
                    </motion.div>}
            </AnimatePresence>
        </div>
    );
}
