import { TextField } from "@mui/material";
import BasePopup from "../../../tools/components/general/BasePopup";
import { CollectorForm, mui_sx } from "../horologer.utils";
import { tasksForm } from "../horologer.utils";
import { useState } from "react";
import { v4 } from "uuid";
import SearchSelect from "../../../tools/components/general/SearchSelect";
import { ServerProps } from "../../../config/server.config";
import MainButton from "../../../tools/components/general/MainButton";
import { useDispatch } from "react-redux";
import { addRequestStatus } from "../../../store/global/actions.global";


export interface PopupAddTaskProps extends ServerProps {
    handleClose : () => void, 
    setTbody : React.Dispatch<React.SetStateAction<CollectorForm>>
}

export default function PopupAddTask(props:PopupAddTaskProps) {
    // * GENERAL VARIABLES
    const dispatch = useDispatch()

    // * STATES
    const [form, setForm] = useState<tasksForm>({
        id : v4(), 
        hours : 0, 
        content : "", 
        service : null, 
        client : null, 
    })

    const handleSubmit = () => {
        if(form.content.replaceAll(" ", '') === "") {
            return dispatch(addRequestStatus({ 
                status : false, 
                message : "Le nom de la tâche est obligatoire"
            }))
        }
        props.setTbody((state:CollectorForm) => {
            return {
                ...state, 
                tasks_descriptions : [...state.tasks_descriptions, form]
            }
        })
        props.handleClose()
    }
    
    return (
        <BasePopup nameClass="add-task" handleClose ={props.handleClose}>
            <h1>Nouvelle tâche</h1>
            <TextField
                id="content"
                label = "Nom de la tâche"
                variant="outlined"
                value = {form.content}
                onChange={(e) => setForm({...form, content : e.target.value})}
                sx={mui_sx} 
            />
            <TextField
                id="hours"
                label = "Heures"
                variant="outlined"
                value = {form.hours}
                onChange={(e) => setForm({...form, hours : e.target.value.replace(/[^-0-9,.]/g, '').replace(/,/g, '.') as any})}
                sx={mui_sx}
            /> 
            <div id = "client">
                <label htmlFor="client">Client</label>
                <SearchSelect
                    {...{
                        text_no_data : "Aucun résultat", 
                        handleClick : (data) => setForm({...form, service : data.complete_info}),
                        handleDelete : () => setForm({...form, service : null}),
                        no_data_text : "Aucun résultat",  
                        server : {
                            axios : props.server,
                            route : `/horologer/collector/search-clients`,
                            //"/configuration/service_families/search-families"

                        },
                        value : form.service !== null ? form.service._id : null,
                    }}
                />
            </div>
            <div id = "service">
                <label htmlFor="service">Service</label>
                <SearchSelect
                    {...{
                        text_no_data : "Aucun résultat", 
                        handleClick : (data) => setForm({...form, service : data.complete_info}),
                        handleDelete : () => setForm({...form, service : null}),
                        no_data_text : "Aucun résultat",  
                        server : {
                            axios : props.server,
                            route : `/horologer/collector/search-services`,
                            //"/configuration/service_families/search-families"

                        },
                        value : form.service !== null ? form.service._id : null,
                    }}
                />
            </div>

            <MainButton
                handleClick = {handleSubmit}
            >
                Enregistrer
            </MainButton>
    
        </BasePopup>
    )
}