import React, { useState, useEffect } from 'react';
import BasePopup from "../../../tools/components/general/BasePopup";
import { ReactComponent as HorologerIcon } from '../../../assets/svg/icons/horologer.svg';
import MainButton from "../../../tools/components/general/MainButton";
import { motion } from 'framer-motion';
import { useDispatch } from 'react-redux';
import { set_popup_warning } from '../../../store/global/actions.global';
import { HorologerManager } from '../Horologer';

export interface StopwatchProps {
    handleClose: () => void;
    manager : HorologerManager, 
    setManager : React.Dispatch<React.SetStateAction<HorologerManager>>
}

function msToHours(milliseconds:number) {
    const hours = milliseconds / 3600000;
    return hours.toFixed(2); // Formate le nombre avec un chiffre après la virgule
}


export default function Stopwatch(props: StopwatchProps) {
    //* GENERAL VARIABLES
    const dispatch = useDispatch();

    // * STATES
    const [isRunning, setIsRunning] = useState<boolean>(false);
    const [isPaused, setIsPaused] = useState<boolean>(false);
    const [startTime, setStartTime] = useState<number | null>(null);
    const [pauseTime, setPauseTime] = useState<number | null>(null);
    const [elapsedTime, setElapsedTime] = useState<number>(0);

    // Restaurer l'état du chronomètre à partir du stockage local au démarrage
    useEffect(() => {
        const savedIsRunning = localStorage.getItem('stopwatchIsRunning');
        const savedIsPaused = localStorage.getItem('stopwatchIsPaused');
        const savedStartTime = localStorage.getItem('stopwatchStartTime');
        const savedPauseTime = localStorage.getItem('stopwatchPauseTime');
        const savedElapsedTime = localStorage.getItem('stopwatchElapsedTime');

        if (savedIsRunning === 'true') {
            setIsRunning(true);
        }

        if (savedIsPaused === 'true') {
            setIsPaused(true);
        }

        if (savedStartTime !== null) {
            setStartTime(parseInt(savedStartTime, 10));
        }

        if (savedPauseTime !== null) {
            setPauseTime(parseInt(savedPauseTime, 10));
        }

        if (savedElapsedTime !== null) {
            setElapsedTime(parseInt(savedElapsedTime, 10));
        }
    }, []);

    useEffect(() => {
        let interval: NodeJS.Timeout | undefined;

        if (isRunning && !isPaused) {
            interval = setInterval(() => {
                const updatedElapsedTime = Date.now() - (startTime ?? 0) + (pauseTime ?? 0);
                setElapsedTime(updatedElapsedTime);
                // Mettre à jour le stockage local
                localStorage.setItem('stopwatchElapsedTime', updatedElapsedTime.toString());
            }, 1000);
        }

        return () => {
            if (interval) clearInterval(interval);
        };
    }, [isRunning, isPaused, startTime, pauseTime]);

    const startTimer = () => {
        const now = Date.now();
        setIsRunning(true);
        setIsPaused(false);
        const newStartTime = now - (pauseTime ?? 0);
        setStartTime(newStartTime);
        setPauseTime(null);
        // Sauvegarder dans le stockage local
        localStorage.setItem('stopwatchIsRunning', 'true');
        localStorage.setItem('stopwatchIsPaused', 'false');
        localStorage.setItem('stopwatchStartTime', newStartTime.toString());
        localStorage.removeItem('stopwatchPauseTime');
    };

    const pauseTimer = () => {
        setIsPaused(true);
        const now = Date.now();
        const updatedElapsedTime = now - (startTime ?? 0) + (pauseTime ?? 0);
        setPauseTime(updatedElapsedTime);
        // Enregistrer le temps écoulé
        localStorage.setItem('stopwatchIsPaused', 'true');
        localStorage.setItem('stopwatchPauseTime', updatedElapsedTime.toString());
    };

    const resumeTimer = () => {
        setIsPaused(false);
        const now = Date.now();
        const newStartTime = now - (elapsedTime - (pauseTime ?? 0));
        setStartTime(newStartTime);
        // Sauvegarder dans le stockage local
        localStorage.setItem('stopwatchIsPaused', 'false');
        localStorage.setItem('stopwatchStartTime', newStartTime.toString());
        localStorage.removeItem('stopwatchPauseTime');
    };

    const stopTimer = () => {
        setIsRunning(false);
        setIsPaused(false);
        setElapsedTime(0);
        setStartTime(null);
        setPauseTime(null);
        // Nettoyer le stockage local
        localStorage.removeItem('stopwatchIsRunning');
        localStorage.removeItem('stopwatchIsPaused');
        localStorage.removeItem('stopwatchStartTime');
        localStorage.removeItem('stopwatchPauseTime');
        localStorage.removeItem('stopwatchElapsedTime');

        const time_in_hours = msToHours(elapsedTime)

        props.setManager((state:HorologerManager) => {
            return {
                ...state, 
                stopwatch_popup : false,
                collector : true,
                stopwatch_result : {
                    date : new Date(), 
                    //hours : time_in_hours
                    hours : 5
                }
            }
        })
    };

    const formatTime = (time: number) => {
        const seconds = Math.floor((time / 1000) % 60);
        const minutes = Math.floor((time / (1000 * 60)) % 60);
        const hours = Math.floor(time / (1000 * 60 * 60));

        const formattedHours = hours < 10 ? `0${hours}` : hours;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
        const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    };

    const decomposeTime = (time: number) => ({
        hours: Math.floor(time / (1000 * 60 * 60)),
        minutes: Math.floor((time / (1000 * 60)) % 60),
        seconds: Math.floor((time / 1000) % 60),
    });

    const timeAnimation = {
        initial: { rotate: -90, opacity: 0 },
        animate: { rotate: 0, opacity: 1 },
        exit: { rotate: 90, opacity: 0 }
    };

    const { hours, minutes, seconds } = decomposeTime(elapsedTime);

    return (
        <BasePopup handleClose={() => {
            if(elapsedTime !== 0) {
                return dispatch(set_popup_warning({
                    status : true, 
                    text : "Souhaitez-vous conserver votre temps ?", 
                    handleYes : () => {
                        props.handleClose()
                    },
                    handleNo : () => {
                        localStorage.removeItem('stopwatchElapsedTime')
                        props.handleClose()
                    }
                }))
            }
            props.handleClose()
        }} nameClass="stopwatch">
            <div className="stopwatch--introduction">
                <div className="introduction--icon">
                    <HorologerIcon />
                </div>
                <div className="introduction--content">
                    <p>Je suis Horologer, votre gardien du temps, prêt à surveiller chaque seconde pour vous.</p>
                    <p>Commencez une tâche, et je garderai le temps pour vous aider à être productif.</p>
                </div>
            </div>
            <div className="stopwatch--timer">
                <motion.span key={`hours`} variants={timeAnimation} initial="initial" animate="animate">{hours < 10 ? `0${hours}` : hours}</motion.span>:
                <motion.span key={`minutes`} variants={timeAnimation} initial="initial" animate="animate">{minutes < 10 ? `0${minutes}` : minutes}</motion.span>:
                <motion.span key={`seconds`} variants={timeAnimation} initial="initial" animate="animate">{seconds < 10 ? `0${seconds}` : seconds}</motion.span>
            </div>
            <div className="stopwatch--controls">
                {!isRunning && (
                    <MainButton handleClick={startTimer} nameClass="start">
                        Démarrer
                    </MainButton>
                )}
                {isRunning && !isPaused && (
                    <MainButton handleClick={pauseTimer} nameClass="pause">
                        Pause
                    </MainButton>
                )}
                {isPaused && (
                    <MainButton handleClick={resumeTimer} nameClass="resume">
                        Reprendre
                    </MainButton>
                )}
                {(isRunning || isPaused) && (
                    <MainButton handleClick={stopTimer} nameClass="stop">
                        Arrêter
                    </MainButton>
                )}
            </div>
        </BasePopup>
    );
}
