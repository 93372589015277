import { useDispatch, useSelector } from 'react-redux';
import { account_user } from '../../store/account/selector.account';
import BaseContainer from '../../tools/components/general/BaseContainer';
import { ServerProps } from '../../config/server.config';
import BaseApp from '../../tools/components/general/BaseApp';
import { useTranslation } from 'react-i18next';
import face_img from '../../assets/images/tysface.png'
import { Icons } from 'tc-minibox';
import { useEffect } from 'react';
import { setLoaderStatus } from '../../store/global/actions.global';

export interface HomepageProps extends ServerProps {

}


export default function Homepage(props:HomepageProps) {
    const user_account = useSelector(account_user)
    const dispatch = useDispatch()


    return (
        <BaseApp>
            <BaseContainer nameClass = "homepage">
                <div className="homepage--title">
                    Bonjour Tys, 
                </div>
                <div className='homepage--left-side'>
                    <div className='homepage--left-side--row'>
                        <div className='mod--user'>
                            <div className='mod--user--avatar'>
                                <img src = {face_img} alt = "Avatar"/>
                            </div>
                            <div className='mod--user--infos'>
                                <p className='username'>Tys Bulin</p>
                                <p className='function'>Développeur informatique</p>
                                <p className='status status--online'>
                                    <span className='bubble'></span>
                                    <span>Online</span>
                                </p>
                            </div>
               

                
                        </div>
                        <div className='mod--time'>
                            <h1>Temps</h1>
                            <div className='mod--time--hour'>
                                <p>20h</p>
                                <p>En novembre</p>
                            </div>
                            <div className='mod--time--rate mod--time--rate--positive'>
                                <Icons name = "arrow-down" mode = "duotone" color = "#6edf90" />
                                <p>5%</p>
                            </div>
                        </div>
                        <div className='mod--favorite-client'>
                            <h1>OGEC</h1>
                            <div className='mod--favorite-client--hour'>
                                <p>11h</p>
                                <p>En novembre</p>
                            </div>
                            <div className='mod--favorite-client--rate mod--favorite-client--rate--positive'>
                                <Icons name = "arrow-down" mode = "duotone" color = "#6edf90" />
                                <p>1.1%</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='homepage--right-side'>
                    <div className='mod--ticket'>
                        <h1>Tickets</h1>
                        <div className='mod--ticket--list'>
                            <div className='list--container'>
                                <div className='list--container--item'>
                                    <div className='checkbox'></div>
                                    <p id='client'></p>
                                    <p id = "object"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </BaseContainer>
        </BaseApp>

    )
}

/*                    
    <motion.div>
        <Icons name = "magnifying-glass" {...icons_config} />
        <input type = "text" placeholder='Rechercher une affaire' />
    </motion.div>
*/