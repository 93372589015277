/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react' ; 
import { useDispatch, useSelector } from 'react-redux';
import { ServerProps } from '../../config/server.config';
import { setLoaderStatus, addRequestStatus, set_popup_warning } from '../../store/global/actions.global';
import { AnimatePresence } from 'framer-motion';
import BaseApp from '../../tools/components/general/BaseApp';
import BaseContainer from '../../tools/components/general/BaseContainer';
import { icons_config, stepsText_position } from '../../config/context.config';
import { DataType, TableForProps } from 'tc-minibox/dist/types/table/index.table';
import { Table } from 'tc-minibox';
import { PaymentType, paymentDeadlines, type_payments } from './utils.clients';
import ClientManager from './layouts/Manager';
import { useTranslation } from 'react-i18next';
import StepsText from '../../tools/components/general/StepsText';
import { account_user } from '../../store/account/selector.account';
import SelectedClient from './layouts/widgets/SelectedClient';


export type ClientsManager = {
    add : boolean
    initialize : boolean, 
    mode : "listing" | "detail", 
    selected_data? : string | null,
    update : {
        status : boolean, 
        content : any
    } 
}

const initialize_update = {
    status : false, 
    content : {}
}

export default function Clients(props:ServerProps) {
    // * VARIABLES
    const dispatch = useDispatch()
    const {t} = useTranslation()
    const user_account = useSelector(account_user)
    const admin = user_account.infos.permission_type === "admin"


    //TO MANAGE COMPONENT
    const [manager, setManager] = useState<ClientsManager>({
        add : false, 
        initialize : false,
        mode : "listing",
        selected_data : null, 
        update : initialize_update
    })
    const [clients, setClients] = useState<DataType>([])

    //DATA 
    const tbody = clients.map(supp => {
        return {
            ...supp, 
            payment_choice : type_payments.find((tp:PaymentType) => tp.name === supp.payment_choice)?.text[`en`], 
            payment_deadline : paymentDeadlines.find(pd => pd.name === supp.payment_deadline)?.text
        }
    })

    // * USE EFFECTS

    //INITIALIZE
    useEffect(() => {
        if(!manager.initialize) {
            dispatch(setLoaderStatus({status : true, message : "Chargement des clients en cours..."}))
            props.server.get('general/clients/initialize')
            .then((res:any) => {
                setManager(state => { return {...state, initialize : true}})
                setClients(res.data.data)
                dispatch(setLoaderStatus({status : false, message : ""}))
            })
            .catch(err => {
                  dispatch(addRequestStatus({
                status : err.response.data.status, 
                message : t(err.response.data.message)
            }))
                dispatch(setLoaderStatus({status : false}))
            })
        }
    }, [manager.initialize])

    // * FUNCTIONS 
    const handleDelete = (e:any) => {
        dispatch(setLoaderStatus({status : true}))
        const selected_data = e.map((sup:any) => sup._id)
        props.server.post('general/clients/delete', {ids_to_delete : selected_data})
        .then(res => {
            setClients(state => state.filter((st:any) => !selected_data.includes(st._id)))
            dispatch(addRequestStatus({
                status : true, 
                message : t(res.data.message)
            }))
            dispatch(setLoaderStatus({status : false}))
        })
        .catch(err => {
                dispatch(addRequestStatus({
            status : err.response.data.status, 
            message : t(err.response.data.message)
        }))
            dispatch(setLoaderStatus({status : false}))
        })
        
    }
    const handleDoubleClick = (selected_data:any) => {
        const sd = clients.find(supp => supp._id === selected_data._id)
        

        setManager((state:ClientsManager) => {
            return {
                ...state, 
                selected_data : sd._id
            }
        })

        // MODIFY USER
        /*setManager(state => {
            return{
                ...state, 
                add : true, 
                update : {
                    status : true, 
                    content : {
                        ...sd, 
                    }
                }
        }})*/
    }

    // * COMPONENTS CONFIG
    const table_config:TableForProps = {
        height : 45, 
        editing : false, 
        icons : icons_config, 
        config : [
            {
                info : {
                    text : "Code", 
                    name : "code", 
                }, 
    
                style : {
                    width : "100px",
                }
            },
            {
                info : {
                    text : "Nom", 
                    name : "name", 
                }, 
    
                style : {
                    width : "calc(100% - 200px - 100px)",
                }
            },
            {
                info : {
                    text : "Choix de paiement", 
                    name : "payment_choice",
                }, 
                style : {
                    width : `200px`, 
                    tbody : {
                        textAlign : "center"
                    }
                }
            },  
        ], 
        data : tbody,
        setData : setClients,
        header : {
            buttons : {
                include : admin ? ["add", "delete"] : ["add"], 
                actions : {
                    add: () => setManager(state => {return {...state, add : true}}), 
                    delete : (e:any) => {
                        if(e.length === 0) {
                            return dispatch(addRequestStatus({
                                status : false, 
                                message : "Veuillez sélectionner au moins un client."
                            }))
                        }

                        return dispatch(set_popup_warning({
                            status : true, 
                            text : "Êtes-vous sûr de vouloir supprimer ce(s) client(s) ?", 
                            handleYes : () => handleDelete(e)
                        }))

                    }, 

                }
            }
        }, 
        handleDoubleClick : (tr:any) => handleDoubleClick(tr)
    }


    return (
        <React.Fragment>
            {
                manager.selected_data !== null ? 
                    <SelectedClient 
                        server={props.server}
                        handleClose = {() => setManager(state => {return{...state, selected_data : null}})}
                        selected_client = {clients.find(supp => supp._id === manager.selected_data)}
                        handleUpdate = {(data:any) => setManager(state => {return{...state, add : true, update : {status : true, content : data}}})}
                    />

                : 
                    null
            }
            {

                manager.add ? 
                    <ClientManager
                        update = {manager.update}
                        data = {clients}
                        setData = {setClients}
                        server = {props.server}
                        handleAdd = {(val) => {
                            setClients(state => {return[val, ...state]})
                        }}
                        handleClose = {() => setManager(state => {return{...state, add : false, update : initialize_update}})}
                    />
                : 
                    null
            }

            <BaseApp>
                <BaseContainer nameClass = "clients">
                    <StepsText 
                        title = {"Clients"}
                        position = {stepsText_position}
                    />
                    <Table {...table_config}/>
                </BaseContainer>
            </BaseApp>



        </React.Fragment>
    )
}