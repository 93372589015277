import React from 'react';
import { Paper, Tab, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { CollectorForm, tasksForm } from '../horologer.utils';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // pour les styles par défaut
import { Icons } from 'tc-minibox';
import SearchSelect from '../../../tools/components/general/SearchSelect';
import { ServerProps } from '../../../config/server.config';
import { TableForProps } from 'tc-minibox/dist/types/table/index.table';
import { icons_config } from '../../../config/context.config';
import {Table} from 'tc-minibox'
import { useDispatch } from 'react-redux';
import { addRequestStatus, set_popup_warning } from '../../../store/global/actions.global';



// THEAD : client, code prest, content, hours
export interface TableTasksProps extends ServerProps {
    tbody : tasksForm[], 
    setTbody : React.Dispatch<React.SetStateAction<CollectorForm>>, 
    handleAddTask : () => void 
}

interface TableTasksManager {
    add : boolean,
}

export default function TableTasks(props:TableTasksProps) {
    // * GENERAL VARIABLES
    const dispatch = useDispatch()

    // * STATES
    const [manager, setManager] = React.useState<TableTasksManager>({add : false})

    // * FUNCTIONS
    const handleDeleteTask = (e:any) => {
        const selected_ids = e.map((el:any) => el.id)
        props.setTbody((state:CollectorForm) => {
            return {
                ...state, 
                tasks_descriptions : state.tasks_descriptions.filter((task:any) => !selected_ids.includes(task.id))
            }
        })
    }

    const tbody = props.tbody.map((tb:any) => {
        return {
            ...tb, 
            tc_code_client : tb.client === null ? "À définir" : tb.client.code??"", 
            tc_code_prest : tb.service === null ? "À définir" : tb.service.code??"", 
        }
    })


    const table_config:TableForProps = {
        height : 45, 
        editing : false, 
        icons : icons_config, 
        config : [
            {
                info : {
                    text : "Client", 
                    name : "tc_code_client", 
                    custom : (tr:any, _) => {
                        return <Tippy 
                        content={
                            <SearchTippy 
                                label = {"Client"}
                                value = {tr.client === null ? null : tr.client._id} 
                                no_data_text = "Aucun résultat"
                                route = {`/horologer/collector/search-clients`}
                                handleClick={(data) => props.setTbody((state:CollectorForm) => {

                                    return {
                                        ...state, 
                                        tasks_descriptions : state.tasks_descriptions.map((task:any) => {
                                            if (task.id === tr.id) {
                                                return {
                                                    ...task, 
                                                    "client" : data.complete_info
                                                }
                                            } else {
                                                return task
                                            }
                                        })
                                    }

                                })}
                                handleDelete={() => props.setTbody((state:CollectorForm) => {
                                    return {
                                        ...state, 
                                        tasks_descriptions : state.tasks_descriptions.map((task:any) => {
                                            if (task.id === tr.id) {
                                                return {
                                                    ...task, 
                                                    "client" : null
                                                }
                                            } else {
                                                return task
                                            }
                                        })
                                    }
                                })}
                                server = {props.server}
                            /> 
                        }
                        trigger="click" 
                        animation="scale" 
                        delay={[100, 100]}
                        interactive = {true}
                        popperOptions={{
                            modifiers: [
                              {
                                name: 'flip',
                                options: {
                                  fallbackPlacements: ['left', 'bottom', 'top'], // Essayer ces placements si le placement préféré n'est pas possible
                                },
                              },
                              {
                                name: 'hide',
                                options: {
                                  boundary: document.body, // Se réfère à la limite pour décider si le popper doit être caché
                                },
                              },
                            ],
                          }}
                        >
                        <p>{`${tr.tc_code_client}`}</p>
                    </Tippy> 
                    }
                }, 
    
                style : {
                    width : "100px",
                }
            },
            {
                info : {
                    text : "Code prest", 
                    name : "name", 
                    custom : (tr:any, _) => {
                        return <Tippy 
                        content={
                            <SearchTippy 
                                label = {"Prestation"}
                                value = {tr.service === null ? null : tr.service._id} 
                                no_data_text = "Aucun résultat"
                                route = {`/horologer/collector/search-services`}
                                handleClick={(data) => props.setTbody((state:CollectorForm) => {
                                    return {
                                        ...state, 
                                        tasks_descriptions : state.tasks_descriptions.map((task:any) => {
                                            if (task.id === tr.id) {
                                                return {
                                                    ...task, 
                                                    "service" : data.complete_info
                                                }
                                            } else {
                                                return task
                                            }
                                        })
                                    }

                                })}
                                handleDelete={() => props.setTbody((state:CollectorForm) => {
                                    return {
                                        ...state, 
                                        tasks_descriptions : state.tasks_descriptions.map((task:any) => {
                                            if (task.id === tr.id) {
                                                return {
                                                    ...task, 
                                                    "service" : null
                                                }
                                            } else {
                                                return task
                                            }
                                        })
                                    }
                                })}
                                server = {props.server}
                            /> 
                        }
                        trigger="click" 
                        animation="scale" 
                        delay={[100, 100]}
                        interactive = {true}
                        popperOptions={{
                            modifiers: [
                              {
                                name: 'flip',
                                options: {
                                  fallbackPlacements: ['left', 'bottom', 'top'], // Essayer ces placements si le placement préféré n'est pas possible
                                },
                              },
                              {
                                name: 'hide',
                                options: {
                                  boundary: document.body, // Se réfère à la limite pour décider si le popper doit être caché
                                },
                              },
                            ],
                          }}
                        >
                        <p>{`${tr.tc_code_prest}`}</p>
                    </Tippy> 
                    }
                }, 
    
                style : {
                    width : "100px",
                }
            },
            {
                info : {
                    text : "Description", 
                    name : "content",
                    custom : (tr:any, _) => {
                        return <input
                        type="text"
                        id = "content-input"
                        value={tr.content}
                        onChange={(e) => {
                            
                            props.setTbody((state:CollectorForm) => {

                                return {
                                    ...state, 
                                    tasks_descriptions : state.tasks_descriptions.map((task:any) => {
                                        if (task.id === tr.id) {
                                            return {
                                                ...task, 
                                                content : e.target.value
                                            }
                                        } else {
                                            return task
                                        }
                                    })
                                }
                            })
                        }}
                    />
                        
                    }
                }, 
                style : {
                    width : `400px`, 
                    tbody : {
                        textAlign : "center"
                    }
                }
            },  
            {
                info : {
                    text : "Hours", 
                    name : "hours",
                    custom : (tr:any, _) => {
                        return  <input
                        type="text"
                        id = "hours-input"
                        value={tr.hours}
                        onChange={(e) => {
                            
                            props.setTbody((state:CollectorForm) => {

                                return {
                                    ...state, 
                                    tasks_descriptions : state.tasks_descriptions.map((task:any) => {
                                        if (task.id === tr.id) {
                                            return {
                                                ...task, 
                                                hours : e.target.value.replace(/[^-0-9,.]/g, '').replace(/,/g, '.')
                                            }
                                        } else {
                                            return task
                                        }
                                    })
                                }
                            })
                        }}
                        />
                    }
                }, 
                style : {
                    width : `100px`, 
                    tbody : {
                        textAlign : "center"
                    }
                }
            },  
        ], 
        data : tbody,
        setData : props.setTbody as any,
        header : {
            buttons : {
                include : ["add", "delete"], 
                actions : {
                    add: () => props.handleAddTask(), 
                    delete : (e:any) => {
                        if(e.length === 0) {
                            return dispatch(addRequestStatus({
                                status : false, 
                                message : "Veuillez sélectionner au moins un client."
                            }))
                        }

                        return dispatch(set_popup_warning({
                            status : true, 
                            text : "Êtes-vous sûr de vouloir supprimer ce(s) tâche(s) ?", 
                            handleYes : () => handleDeleteTask(e)
                        }))

                    }, 

                }
            }
        }, 
        handleDoubleClick : (tr:any) => console.log(tr)
    }
    return (
        <div id = "table-tasks"> 
            <Table {...table_config}/>
        </div>
    )
    /*return ( 
        <div id = "table-tasks"> 

            <TableContainer component={Paper} sx={{ maxHeight: 400, overflowX: 'scroll', minWidth: 600 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                    <TableRow>
                        {headers.map((header) => ( 
                        <TableCell key={header.id} sx = {{width : `${header.width}%`, height : "40px"}}>
                            {header.label} 
                        </TableCell>
                        ))}
                    </TableRow>
                    </TableHead>
                    <TableBody>

                    <Table {...table_config}/>
                    {
                    
                    props.tbody.map((row:any, index:number) => (
                        <TableRow key={index} >
                            {headers.map((header:any) => (
                                <TableCell key={header.id} sx = {{width : header.width, height : "40px"}}>
                                    {
                                        ["client", "service"].includes(header.id) ? 
                                            <Tippy 
                                                content={
                                                    <SearchTippy 
                                                        label = {header.id === "client" ? "Client" : "Code Prestation"}
                                                        value = {row[header.id] === null ? null : row[header.id]._id} 
                                                        no_data_text = "Aucun résultat"
                                                        route = {header.id === "client" ? `/horologer/collector/search-clients` : `/horologer/collector/search-services`}
                                                        handleClick={(data) => props.setTbody((state:CollectorForm) => {
                                                            return {
                                                                ...state, 
                                                                tasks_descriptions : state.tasks_descriptions.map((task:any) => {
                                                                    if (task.id === row.id) {
                                                                        return {
                                                                            ...task, 
                                                                            [header.id] : data.complete_info
                                                                        }
                                                                    } else {
                                                                        return task
                                                                    }
                                                                })
                                                            }
        
                                                        })}
                                                        handleDelete={() => props.setTbody((state:CollectorForm) => {
                                                            return {
                                                                ...state, 
                                                                tasks_descriptions : state.tasks_descriptions.map((task:any) => {
                                                                    if (task.id === row.id) {
                                                                        return {
                                                                            ...task, 
                                                                            [header.id] : null
                                                                        }
                                                                    } else {
                                                                        return task
                                                                    }
                                                                })
                                                            }
                                                        })}
                                                        server = {props.server}
                                                    /> 
                                                }
                                                trigger="click" 
                                                animation="scale" 
                                                delay={[100, 100]}
                                                interactive = {true}
                                                popperOptions={{
                                                    modifiers: [
                                                      {
                                                        name: 'flip',
                                                        options: {
                                                          fallbackPlacements: ['left', 'bottom'], // Essayer ces placements si le placement préféré n'est pas possible
                                                        },
                                                      },
                                                      {
                                                        name: 'hide',
                                                        options: {
                                                          boundary: document.body, // Se réfère à la limite pour décider si le popper doit être caché
                                                        },
                                                      },
                                                    ],
                                                  }}
                                                >
                                                <p>{`${row[header.id] === null ?  "À définir" : header.id === "client" ? row[header.id].code : row[header.id].code}`}</p>
                                            </Tippy> 
                                        : 
                                            header.id === "content" ? 
                                                <input
                                                    type="text"
                                                    id = "content-input"
                                                    value={row[header.id]}
                                                    onChange={(e) => {
                                                        
                                                        props.setTbody((state:CollectorForm) => {
            
                                                            return {
                                                                ...state, 
                                                                tasks_descriptions : state.tasks_descriptions.map((task:any) => {
                                                                    if (task.id === row.id) {
                                                                        return {
                                                                            ...task, 
                                                                            content : e.target.value
                                                                        }
                                                                    } else {
                                                                        return task
                                                                    }
                                                                })
                                                            }
                                                        })
                                                    }}
                                                />
                                            : 
                                                header.id === "hours" ? 
                                                    <input
                                                    type="text"
                                                    id = "hours-input"
                                                    value={row[header.id]}
                                                    onChange={(e) => {
                                                        
                                                        props.setTbody((state:CollectorForm) => {
            
                                                            return {
                                                                ...state, 
                                                                tasks_descriptions : state.tasks_descriptions.map((task:any) => {
                                                                    if (task.id === row.id) {
                                                                        return {
                                                                            ...task, 
                                                                            hours : parseFloat(e.target.value.replace(/[^-0-9,.]/g, '').replace(/,/g, '.'))
                                                                        }
                                                                    } else {
                                                                        return task
                                                                    }
                                                                })
                                                            }
                                                        })
                                                    }}
                                                    />
                                                : 
                                                row[header.id]
                                    }
 
                                </TableCell>
                            ))}
                        </TableRow>
                    ))
                    
                    
                    }
                    </TableBody>
                </Table>
            </TableContainer>
            
        </div>
    )*/
}


interface SearchTippyProps extends ServerProps {
    label : string, 
    value : any, 
    no_data_text : string, 
    route : string, 
    body? : any, 
    handleClick : (data:any) => void, 
    handleDelete : () => void, 
}

function SearchTippy(props:SearchTippyProps) {
     
    return (
        <div className='table-tasks-tippy'>
            <label htmlFor = "search-tippy">{props.label}</label>
            <SearchSelect
            {...{
                text_no_data : props.no_data_text, 
                handleClick : (data) => props.handleClick(data),
                handleDelete : () => props.handleDelete(),
                no_data_text : props.no_data_text,  
                server : {
                    axios : props.server,
                    route : props.route,
                    body : props.body?? {}
                    //"/configuration/service_families/search-families"

                },
                value : props.value
            }}
            />
        </div>  
    )
} 