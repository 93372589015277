import {ReactComponent as Logo} from '../assets/svg/logo2.svg'
import React from 'react';
import {ReactComponent as Boxes} from '../assets/svg/icons/boxes-stacked-duotone.svg' ; 
import {ReactComponent as Containers} from '../assets/svg/icons/container-storage-duotone.svg' ;
import logo_min from '../assets/images/logo_minified.png'

// * COMPAGNY
export const compagny = {
    logo : <Logo />, 
    logo_min : logo_min
}

// * ICONS
export const icons_config = {
    mode : "duotone", 
    color : "rgb(79 206 118)"  
}

// * STEPS TEXT POSITION
export const stepsText_position = {
    x : 40,
    y : 0
}

// * MODULES

/* eslint-disable */


interface mod_links_interface {
    id : string, 
    name : string, 
    svg : JSX.Element, 
    subdomain : string, 
    text: string
}

const boxesElement: JSX.Element = React.createElement(Boxes);
const containersElement: JSX.Element = React.createElement(Containers);

export const modules_links:mod_links_interface[] = [
    {
        id : "MOD-LINKS-1",  
        name : "stocks", 
        text : "Stocks",
        svg :  boxesElement,
        subdomain : "https://stocks.mynyomi.fr"
    }, 
    {
        id : "MOD-LINKS-02", 
        name : "containers", 
        text : "Conteneurs",
        svg :  containersElement,
        subdomain : "https://containers.mynyomi.fr"
    }, 
]