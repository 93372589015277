//import io from 'socket.io-client' ; 
import { Axios } from "axios"
import { io } from "socket.io-client";

export interface ServerProps {
    server : Axios
}

export const hostname = 'http://localhost:4000'
//export const hostname = 'https://mynyomi.fr'

// * SOCKET CONFIG ///////////``
export const socket_server = io(hostname, { transports : ['polling']});
//export const socket_server = io('https://mynyomi.fr', { transports : ['polling']});

// * NODEJS SERVER ///////////
//export const server_host = (route:string="") => { return `https://mynyomi.fr/stocks/${route}` }
export const server_host = (route:string="") => { return `${hostname}/app/${route}`}


// * NODEJS SERVER ///////////
//export const server_host = (route:string="") => { return `https://mynyomi.fr/stocks/${route}`}
export const server_public = (route:string="") => { return `${hostname}/app/${route}`}
