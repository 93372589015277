import { useState } from "react"
import {AnimatePresence, motion} from 'framer-motion'; 
import { type_payments } from "../../utils.clients";
import {ReactComponent as OnlineTransaction} from '../../../../assets/svg/illustrations/online_transaction.svg';
import DropDown from "../../../../tools/components/general/DropDown";
import { useTranslation } from "react-i18next";


export interface PaymentChoiceProps {
    form : any, 
    setForm : React.Dispatch<React.SetStateAction<any>>, 
    handleChangeInput : (e:React.ChangeEvent<HTMLInputElement>, reg:any) => void
}

export default function PaymentChoice(props:PaymentChoiceProps) {
    // * GENERAL VARIABLES
    const {t, i18n} = useTranslation()
    const current_language = i18n.language;
    const translate = (key:string) => t(`suppliers.manager.labels.${key}`)


    const [events, setEvents] = useState({
        hover : null
    })

    return (
        <motion.div className='payment'>
            <div className='payment_choice'>
                <label htmlFor='payment_choice'>{translate("payment_choice")}</label>
                <DropDown
                    reference = {"TYPE_OF_PAYMENT_HOVER"}
                    hover = {events.hover}
                    setHover = {(e) => setEvents(state => {return{...state, hover : e}})}
                    value = {props.form.payment_choice}
                    setValue = {(e) => props.setForm((state:any) => {return{...state, payment_choice : e.name}})}
                    list = {type_payments.map((tp:any) => {
                        return {
                            ...tp, 
                            text : tp.text[current_language]
                        }
                    })}
                />
            </div>
            <AnimatePresence>
            {
                props.form.payment_choice === "bank_transfer" ?
                    <motion.div 
                        {...{
                            exit : {opacity : 0, scale : 0.5},   
                            animate : {opacity : 1, scale : 1, transition : {delay : 0.2 ,duration : 0.5}},
                            initial : {opacity : 0, scale : 0.5}
                        }}
                        className='payment_info'
                    >
                        <div className='codes'>
                            <div className='account_number'>
                                <label htmlFor='account_number'>IBAN</label>
                                <input
                                    type = "text"
                                    name = "payment_info"
                                    value = {props.form.payment_info.account_number}
                                    onChange = {(e:React.ChangeEvent<HTMLInputElement>) => {
                                        props.setForm((state:any) => {
                                            return {
                                                ...state, 
                                                payment_info : {
                                                    ...state.payment_info, 
                                                    account_number : e.target.value
                                                }
                                            }
                                        })
                                    }}
                                />
                            </div>
                            <div className='bank_code'>
                                <label htmlFor='bank_code'>BIC</label>
                                <input
                                    type = "text"
                                    name = "payment_info"
                                    value = {props.form.payment_info.bank_code}
                                    onChange = {(e:React.ChangeEvent<HTMLInputElement>) => {
                                        props.setForm((state:any) => {
                                            return {
                                                ...state, 
                                                payment_info : {
                                                    ...state.payment_info, 
                                                    bank_code : e.target.value
                                                }
                                            }
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        <div className='account_holder'>
                            <label htmlFor='account_holder'>Nom du titulaire</label>
                            <input
                                type = "text"
                                name = "account_holder"
                                value = {props.form.payment_info.account_holder}
                                onChange = {(e:React.ChangeEvent<HTMLInputElement>) => {
                                    props.setForm((state:any) => {
                                        return {
                                            ...state, 
                                            payment_info : {
                                                ...state.payment_info, 
                                                account_holder : e.target.value
                                            }
                                        }
                                    })
                                
                                }}
                            />
                        </div>
                    </motion.div>
                    
                : 
                    <motion.div 
                        {...{
                            exit : {opacity : 0, scale : 0.5},   
                            animate : {opacity : 1, scale : 1, transition : {delay : 0.2 ,duration : 0.5}},
                            initial : {opacity : 0, scale : 0.5}
                        }}
                        className='nomodule'>
                        <OnlineTransaction />
                    </motion.div>
            }
            </AnimatePresence>
        </motion.div>
    )
}