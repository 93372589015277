/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react' ; 
import { ServerProps } from '../../../config/server.config';
import { useDispatch, useSelector } from 'react-redux';
import { type_payments } from '../utils.clients';
import { addRequestStatus, setLoaderStatus } from '../../../store/global/actions.global';
import { DataType } from 'tc-minibox/dist/types/table/index.table';
import BasePopup from '../../../tools/components/general/BasePopup';
import MainButton from '../../../tools/components/general/MainButton';
import CategorizerManager from '../../../tools/components/general/Categorizer';
import PostalAddress from './grid/PostalAddress';
import PaymentChoice from './grid/PaymentChoice';
import Contact from './grid/Contact';
import { useTranslation } from 'react-i18next';
import { account_user } from '../../../store/account/selector.account';


export interface ClientsManagerProps extends ServerProps {
    update? : { 
        status : boolean, 
        content : FormClientsManager
    }, 
    supposed_Clients? : {[key : string] : any}, 
    data? : DataType, 
    setData? : React.Dispatch<React.SetStateAction<DataType>>, 
    handleAdd : (data:any) => void,
    handleClose : () => void,
}

/*type ClientsManagerEvents = {
    hover : string | null
}*/

export type FormClientsManager = {
    _id? : any,
    code : string, 
    name : string,
    payment_choice : string,
    payment_info : {
        account_number : string,
        bank_code : string,
        account_holder : string
    },
    payment_deadline : number, 
    mail : string,
    address : string[],
    zip_code : string,
    city : string,
    contact : string[], 
    handleSubmit? : () => void, 
    user : string
}

export default function ClientsManager(props:ClientsManagerProps) {
    // * GENERAL VARIABLES
    const dispatch = useDispatch()
    const user_account = useSelector(account_user)


    const [form, setForm] = useState<FormClientsManager>({
        code : "", 
        name : props.supposed_Clients?.name ? props.supposed_Clients?.name : "", 
        payment_choice : type_payments[0].name, 
        payment_info : {
            account_number : "",
            bank_code : "",
            account_holder : ""
        }, 
        payment_deadline : 1,
        mail : "",
        address : ["", ""],
        zip_code : "",
        city : "",
        contact : [], 
        user : user_account.infos._id
    })

    const handleChangeInput = (e:React.ChangeEvent<HTMLInputElement>, reg:any = '') => {
        const value = e.target.value.replace(reg, '')

        setForm((state:any) => {
            return {
                ...state, 
                [e.target.name] : value
            }
        })
    }

    const config = [
        {
            id : "MOD-00", 
            name : "Adresse",
            svg : "home", 
            component : <PostalAddress
                form = {form}
                handleChangeInput = {handleChangeInput}
                setForm = {setForm}
            />
        }, 
        {
            id : "MOD-01", 
            svg : "money-bill", 
            name : "Paiement",
            component : <PaymentChoice
                form = {form}
                setForm = {setForm}
                handleChangeInput={handleChangeInput}
            />
        }, 
        {
            id : "MOD-02", 
            name : "Contact", 
            svg : "users", 
            component : <Contact
                form = {form}
                setForm = {setForm}
            />
        }, 
    ]

    const [manager, setManager] = useState({
        initialization : "pending", 
        selected_modal : config[0].id
    })

    /*const [events, setEvents] = useState<ClientsManagerEvents>({
        hover : null
    })*/

    const handleSubmit = () => {
        
        if(form.code.trim() === "" || form.code.trim().length < 3) {
            return dispatch(addRequestStatus({
                status : false,
                message : "Le code doit contenir au moins 3 caractères."
            }))
        }
        
        if(form.name.trim() === "") {
            return dispatch(addRequestStatus({
                status : false,
                message : "Le nom ne peut pas être vide."
            }))
        }

        dispatch(setLoaderStatus({status : true}))

        const data = {
            ...form, 
            payment_info : form.payment_info, 
            address : form.address, 
            contact : form.contact,

        }

        return props.server.post('/general/clients/add', data) 
        .then(res => {
            dispatch(setLoaderStatus({status : false}))
            dispatch(addRequestStatus(res.data))
            props.handleClose()
            props.handleAdd(res.data.data)
        })
        .catch(err => {
              dispatch(addRequestStatus(err.response.data))
            dispatch(setLoaderStatus({status : false}))
        })
    }

    //const handleChangeModals = (id:string) => setManager(state => {return{...state, selected_modal : id}})
    //const handleEvents = (event:string, val:any) => setEvents(state => {return{...state, [event] : val}})

    const handleUpdate = () => {
        dispatch(setLoaderStatus({status : true}))
        const data = {
            ...form, 
            /*payment_info : JSON.stringify(form.payment_info), 
            address : JSON.stringify(form.address), 
            contact : JSON.stringify(form.contact),*/
        }

        return props.server.post('general/clients/update', data) 
        .then(res => {
            dispatch(setLoaderStatus({status : false}))
            dispatch(addRequestStatus(res.data))
            props.handleClose()
            if(props.setData) {
                props.setData(state => state.map(el => {
                    if(el._id === form._id) {
                        return form
                    } else {
                        return el
                    }
                }))
            }

        })
        .catch(err => {
              dispatch(addRequestStatus(err.response.data))
            dispatch(setLoaderStatus({status : false}))
        })
    }

    useEffect(() => {
        if(manager.initialization === "pending" && props.update?.status) {
            setForm(props.update?.content)
        }
    }, [])

    return (
        <BasePopup
            nameClass = "clients-creation clients-manager"
            handleClose = {props.handleClose}
        >
            <div className='code'>
                <label htmlFor='code'>{"Code"}</label>
                <input 
                    type = "text"
                    name = "code"
                    placeholder='Ex : CAPP'
                    value = {form.code}
                    onChange = {handleChangeInput}
                />
            </div>
            <div className='name'>
                <label htmlFor='name'>{"Nom"}</label>
                <input 
                    type = "text"
                    name = "name"
                    placeholder='Ex : Apple'
                    value = {form.name}
                    onChange = {(e:React.ChangeEvent<HTMLInputElement>) =>handleChangeInput(e)}
                />
            </div>

            <CategorizerManager config = {config} rerender = {true} />

            {
                props.update?.status ?
                    JSON.stringify(props.update.content) !== JSON.stringify(form) ?
                        <MainButton
                        handleClick = {handleUpdate}
                        >
                            {"Modifier"}
                        </MainButton>
                    : 
                        null
                : 
                    <MainButton
                    handleClick = {handleSubmit}
                    >
                        {"Créer"}
                    </MainButton>
            }


    
        </BasePopup>
    )
}

const modal_container_animation = {
    initial : {opacity : 0, y : 100},
    animate : {y : 0, opacity : 1, transition : {type : "linear", duration : 0.15}},
    exit : {y : 100, opacity : 0},
}





